import Compositions from './';
import CompositionDetails from './CompositionDetails';
import MusicNoteSharpIcon from '@material-ui/icons/MusicNoteSharp';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/compositions',
    title: 'Compositions',
    component: Compositions,
    icon: MusicNoteSharpIcon,
  },
  {
    exact: true,
    component: CompositionDetails,
    path: '/composition/details/:compositionId?',
  },
];

export default routes;
