import { gql } from 'apollo-boost';
import { imageAll } from '_graphql/fragments/images';

const MusicianImageFragment = gql`
  fragment MusicianImageFragment on Musician {
    id
    nodeId
    images: musicianImagesList {
      image {
        ...imageAll
      }
    }
  }

  ${imageAll}
`;

export const FETCH_MUSICIAN_IMAGES = gql`
  query FetchMusicianImages($id: Int!) {
    musician(id: $id) {
      ...MusicianImageFragment
    }
  }

  ${MusicianImageFragment}
`;

export const ADD_MUSICIAN_IMAGE = gql`
  mutation AddMusicianImage(
    $musicianId: Int!
    $imageId: Int!
  ) {
    createMusicianImage(input: {
      musicianImage: {
        imageId: $imageId
        musicianId: $musicianId
      }
    }){
      musician {
        ...MusicianImageFragment
      }
    }
  }

  ${MusicianImageFragment}
`;

export const DELETE_MUSICIAN_IMAGE = gql`
  mutation DeleteMusicianImage(
    $musicianId: Int!
    $imageId: Int!
  ) {
    deleteMusicianImage(input:  { imageId: $imageId, musicianId: $musicianId }){
      musician {
        ...MusicianImageFragment
      }
    }
  }

  ${MusicianImageFragment}
`;
