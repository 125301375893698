import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';


import Form, {
  FormSection,
  JoditTextEditor,
} from 'components/Form';
import Loading from 'components/Loading';
import LocaleTabs from 'components/LocaleTabs';

import {
  findLocale,
  renderFormField,
} from 'helpers';

import {
  FETCH_MUSICIAN_LOCALES,
  CREATE_MUSICIAN_LOCALE,
  UPDATE_MUSICIAN_LOCALE,
} from '_graphql/actions/musicians';

const fields = [
  {
    name: 'firstName',
    required: true,
    label: 'First Name',
    props: {
      className: 'mrg-sides-15',
    },
  },
  {
    required: true,
    name: 'lastName',
    label: 'Last Name',
    props: {
      className: 'mrg-sides-15',
    },
  },
];

const refetchQueries = ['FetchMusicians'];

function MusicianLocales(props) {
  const {
    musicianId,
  } = props;
  
  const { data: { musician = {} } = {}, loading } = useQuery(FETCH_MUSICIAN_LOCALES, {
    variables: {
      id: musicianId,
    },
  });

  const [createMusicianLocale, { loading: crtLoading }] = useMutation(CREATE_MUSICIAN_LOCALE);
  const [updateMusicianLocale, { loading: updLoading }] = useMutation(UPDATE_MUSICIAN_LOCALE);

  const handleFormSubmit = useCallback(locale => {
    const action = locale.nodeId
      ? updateMusicianLocale
      : createMusicianLocale;

    return action({
      variables: {
        musicianId: musicianId,
        ...locale,
      },
      refetchQueries,
    });
  }, [createMusicianLocale, updateMusicianLocale, musicianId]);

  return (
    <Loading isLoading={loading || crtLoading || updLoading}>
      <LocaleTabs
        renderContent={lang => {
          const locale = findLocale(musician.locales, lang.code);
  
          return (
            <Form
              key={lang.code}
              defaultData={locale}
              title="Personal Info"
              isEdit={!!locale.nodeId}
              onSubmit={handleFormSubmit}
              renderContent={(onFormChange, formData) => {
                return (
                  <FormSection>
                    <Grid container spacing={2}>
                      {fields.map(inp => (
                        <Grid item key={inp.name}>
                          {
                            renderFormField({
                              inp,
                              lang,
                              formData,
                              onFormChange,
                              fullWidth: false,
                            })
                          }
                        </Grid>
                      ))}
                    </Grid>

                    <JoditTextEditor
                      required
                      name="biography"
                      label="Biography"
                      dataLang={lang.code}
                      onChange={onFormChange}
                      value={formData.biography || ''}
                    />
                  </FormSection>
                );
              }}
            />
          );
        }}
      />
    </Loading>
  );
}

MusicianLocales.defaultProps = {};

MusicianLocales.propTypes = {
  musicianId: PropTypes.number.isRequired,
};

export default MusicianLocales;
