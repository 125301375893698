import React, { useContext } from 'react';

import { AuthContext } from 'pages/Auth/AuthProvider';

const withUser = Comp => props => {
  const { user, isAdmin, isEditor } = useContext(AuthContext);

  return (
    <Comp
      {...props}
      user={user}
      isAdmin={isAdmin}
      isEditor={isEditor}
    />
  );
};

export default withUser;
