import Users from './index';
import PersonIcon from '@material-ui/icons/Person';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/users',
    title: 'Users',
    component: Users,
    icon: PersonIcon,
  },
];

export default routes;
