import { gql } from 'apollo-boost';
import { articleAll } from '_graphql/fragments';

export const FETCH_ARTICLES = gql`
  query FetchArticles($orderBy: [ArticlesOrderBy!], $limit: Int, $offset: Int) {
    articles(orderBy: $orderBy, first: $limit, offset: $offset) {
     nodes {
        ...articleAll
      }
      totalCount
    }
  }

  ${articleAll}
`;

export const FETCH_SINGLE_ARTICLE = gql`
  query FetchSingleArticle ($id: Int!) {
    article(id: $id) {
      ...articleAll
    }
  }

  ${articleAll}
`;

export const ADD_ARTICLE = gql`
  mutation AddArticle(
    $genres: [Int!]!
    $tags: [Int!]!
    $path: String!
  ) {
    createArticle(
      input: {
        article: { path: $path }
        tags: $tags
        genres: $genres
      }) {
      article {
        ...articleAll
      }
    }
  }

  ${articleAll}
`;

export const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $id: Int!
    $tags: [Int!]
    $posterId: Int
    $genres: [Int!]
    $published: Boolean
    $publishedAt: Datetime
    $galleryId: Int
    $path: String!
  ) {
    updateArticle(
      input: {
        id: $id
        tags: $tags
        genres: $genres
        patch: {
          path: $path
          posterId: $posterId
          published: $published
          galleryId: $galleryId
          publishedAt: $publishedAt
        }
      }
    ) {
      article {
        ...articleAll
      }
    }
  }

  ${articleAll}
`;

export const DELETE_ARTICLE = gql`
  mutation DeleteArticle($id: Int!) {
    deleteArticle(input: { id: $id }) {
      article {
        id
        nodeId
      }
    }
  }
`;
