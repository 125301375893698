import { gql } from 'apollo-boost';
import { scoreAll } from '_graphql/fragments/scores';

export const FETCH_SCORES = gql`
  query FetchScores($limit: Int $offset: Int $orderBy: [ScoresOrderBy!]) {
    scores(first: $limit, offset: $offset orderBy: $orderBy) {
      nodes {
        ...scoreAll
      }
      totalCount
    }
  }

  ${scoreAll}
`;

export const FETCH_SINGLE_SCORE = gql`
  query FetchSingleScore ($id: Int!) {
    score(id: $id) {
      ...scoreAll
    }
  }

  ${scoreAll}
`;

export const CREATE_SCORE = gql`
  mutation CreateScore($path: String!, $prices: JSON!, $instruments: [Int!]) {
    createScore(
      input: {
        instruments: $instruments
        score: {
          prices: $prices
          path: $path
        }
      }) {
      score {
        ...scoreAll
      }
    }
  }

  ${scoreAll}
`;

export const UPDATE_SCORE = gql`
  mutation UpdateScore(
    $id: Int!
    $url: Upload
    $path: String!
    $stampRight: String
    $stampCenter: String
    $prices: JSON
    $poster: Upload
    $compositionId: Int
    $published: Boolean
    $instruments: [Int!]
    $publishedAt: Datetime
    $previewOptions: CreatePreviewOptions
  ) {
    updateScore(
      input: {
        id: $id
        previewOptions: $previewOptions
        instruments: $instruments
        patch: {
          compositionId: $compositionId
          publishedAt: $publishedAt
          stampCenter: $stampCenter
          stampRight: $stampRight
          published: $published
          prices: $prices
          poster: $poster
          path: $path
          url: $url
        }
      }
    ) {
      score {
        ...scoreAll
      }
    }
  }

  ${scoreAll}
`;

export const DELETE_SCORE = gql`
  mutation DeleteScore($id: Int!) {
    deleteScore(input: { id: $id }) {
      score {
        id
        nodeId
      }
    }
  }
`;
