import React, {
  useCallback,
  createContext,
} from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Loading from 'components/Loading';
import Auth from 'pages/Auth';

import {
  LOGOUT,
  SIGN_IN,
  AUTHENTICATE_USER,
} from '_graphql/actions';

export const AuthContext = createContext({});

function AuthProvider(props) {
  const {
    loading: isUserLoading,
    data: { currentUser = {} } = {},
  } = useQuery(AUTHENTICATE_USER, {
    notifyOnNetworkStatusChange: true,
  });
  
  const [signIn, { loading: isSignInLoading }] = useMutation(SIGN_IN);
  const [logOut, { loading: isLogOutLoading }] = useMutation(LOGOUT);
 
  const handleSignIn = useCallback(async data => {
    const res = await signIn({
      variables: data,
    });
    
    if (res) {
      if (res.data && res.data.login && res.data.login.message) {
        alert(res.data.login.message);
      } else {
        window.location.reload();
      }
    }
  }, [signIn]);
  
  const handleLogout = useCallback(async () => {
    await logOut({
      refetchQueries: ['CurrentUser'],
    });
    window.location.reload();
  }, [logOut]);
  
  const userData = currentUser || {};
  const isUserLoggedIn = () => !!userData.id;
  
  return (
    <AuthContext.Provider
      value={{
        isUserLoggedIn,
        user: currentUser,
        logOut: handleLogout,
        signIn: handleSignIn,
        isAdmin: userData.role === 'ADMIN',
        isEditor: userData.role === 'EDITOR',
        isUserLoading: isUserLoading || isSignInLoading || isLogOutLoading,
      }}
    >
      {isUserLoading ?
        <Loading isLoading />
        : userData.id
          ? props.children
          : <Auth />
      }
    </AuthContext.Provider>
  );
}

AuthProvider.defaultProps = {};

AuthProvider.propTypes = {};

export default AuthProvider;
