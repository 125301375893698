import { gql } from 'apollo-boost';

export const FETCH_OPEN_MESSAGES = gql`
  query FetchOpenMessages($orderBy: [OpenMessagesOrderBy!], $limit: Int, $offset: Int) {
    openMessages(orderBy: $orderBy, first: $limit, offset: $offset) {
      nodes {
        attachedFile
        createdAt
        message
        nodeId
        email
        name
        id
      }
      totalCount
    }
  }
`;

export const DELETE_OPEN_MESSAGE = gql`
  mutation DeleteOpenMessage($id: Int!) {
    deleteOpenMessage(input: { id: $id }) {
      openMessage {
        id
        nodeId
      }
    }
  }
`;
