import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Loading from 'components/Loading';
import Form, {
  FormSection,
  JoditTextEditor,
} from 'components/Form';
import LocaleTabs from 'components/LocaleTabs';

import Grid from '@material-ui/core/Grid';

import {
  findLocale,
  renderFormField,
} from 'helpers';

import {
  FETCH_ARTICLE_LOCALES,
  CREATE_ARTICLE_LOCALE,
  UPDATE_ARTICLE_LOCALE,
} from '_graphql/actions/articles';

const fields = [
  {
    name: 'title',
    required: true,
    label: 'Article Title',
  },
  {
    required: true,
    name: 'description',
    label: 'Article Description',
  },
];

const refetchQueries = ['FetchArticles'];

function ArticleLocales(props) {
  const { articleId } = props;
  const { data: { article = {} } = {}, loading } = useQuery(FETCH_ARTICLE_LOCALES, {
    variables: {
      id: articleId,
    },
  });

  const [createArticleLocale, { loading: crtLoading }] = useMutation(CREATE_ARTICLE_LOCALE);
  const [updateArticleLocale, { loading: updLoading }] = useMutation(UPDATE_ARTICLE_LOCALE);

  const handleFormSubmit = useCallback(locale => {
    const action = locale.nodeId
      ? updateArticleLocale
      : createArticleLocale;

    return action({
      variables: {
        articleId: articleId,
        ...locale,
      },
      refetchQueries,
    });

  }, [updateArticleLocale, createArticleLocale, articleId]);

  return (
    <Loading isLoading={loading || crtLoading || updLoading}>
      <LocaleTabs
        renderContent={lang => {
          const locale = findLocale(article.locales, lang.code);
          
          return (
            <Form
              title="Content"
              key={lang.code}
              defaultData={locale}
              isEdit={!!locale.nodeId}
              onSubmit={handleFormSubmit}
              renderContent={(onFormChange, formData) => {
                return (
                  <FormSection>
                    <Grid container>
                      {fields.map(inp => renderFormField({ inp, formData, onFormChange, lang }))}
                    </Grid>

                    <JoditTextEditor
                      required
                      name="content"
                      label="Content"
                      dataLang={lang.code}
                      onChange={onFormChange}
                      value={formData.content || ''}
                    />
                  </FormSection>
                );
              }}
            />
          );
        }}
      />
    </Loading>
  );
}

ArticleLocales.defaultProps = {};

ArticleLocales.propTypes = {
  articleId: PropTypes.number.isRequired,
};

export default ArticleLocales;
