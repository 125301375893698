import React, { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import Table from 'components/Table';

import BaseSection from 'sections/BaseSection';

import {
  FETCH_PROMO_CODES,
  DELETE_PROMO_CODE,
} from '_graphql/actions';

import {
  goToDetails,
  searchInValues,
} from 'helpers';

const tableColumns = [
  {
    title: 'Status',
    name: 'status',
  },
  {
    title: 'Percent',
    name: 'percent',
  },
  {
    title: 'Created on',
    name: 'createdAt',
  },
  {
    title: 'Expires',
    name: 'expiresAt',
  },
];

function PromoCodes(props) {
  const [deletePromoCode, { loading: isDeleteLoading }] = useMutation(DELETE_PROMO_CODE);

  const {
    history,
  } = props;

  const handleAddButtonClick = useCallback(row => {
    goToDetails(history, 'promo-code')(row, 'code');
  }, [history]);

  const handleDelete = useCallback(async (nodeId, code) => {
    await deletePromoCode({
      variables: {
        code: code.code,
      },
      refetchQueries: ['FetchPromoCodes'],
    });
  }, [deletePromoCode]);

  return (
    <BaseSection
      title="Promo Codes"
      isLoading={isDeleteLoading}
      onAddButtonClick={handleAddButtonClick}
    >
      <Table
        dataKey="promoCodes"
        onDelete={handleDelete}
        tableColumns={tableColumns}
        onRowFilter={searchInValues}
        onEdit={handleAddButtonClick}
        fetchQuery={FETCH_PROMO_CODES}
        onDoubleClick={handleAddButtonClick}
      />
    </BaseSection>
  );
}

PromoCodes.defaultProps = {};

PromoCodes.propTypes = {};

export default PromoCodes;
