import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Image from 'components/Image';

import SectionWithFetchMore from 'sections/SectionWithFetchMore';

import { noop } from 'helpers';

const styles = {
  image: {
    margin: 5,
  },
};

function ImagesModalContent(props) {
  const {
    images,
    classes,
    pageInfo,
    fetchMore,
    isLoading,
    removeImage,
    queryDataKey,
    selectedImages,
    handleImageSelect,
  } = props;
  
  return (
    <SectionWithFetchMore
      items={images}
      isLoading={isLoading}
      fetchMore={fetchMore}
      queryDataKey={queryDataKey}
      hasNextPage={pageInfo.hasNextPage}
      renderItem={(image, index) => (
        <Image
          size={300}
          key={index}
          image={image}
          src={image.url}
          onUrlCopy={noop}
          onDelete={removeImage}
          className={classes.image}
          onClick={handleImageSelect}
          selected={selectedImages.map(img => img.id).includes(image.id)}
        />
      )}
    />
  );
}

ImagesModalContent.defaultProps = {
  pageInfo: {},
};

ImagesModalContent.propTypes = {
  removeImage: PropTypes.func,
  selectedImages: PropTypes.array,
  handleImageSelect: PropTypes.func
};

export default withStyles(styles)(ImagesModalContent);
