import languages from './languages';
import userRoles from './userRoles';
import musicianTypes from './musicianTypes';

const discardMessage = 'You have unsaved changes. Are you sure you want to leave the page?';
const removeMessage = 'Are you sure you want to delete this item?';

export {
  discardMessage,
  removeMessage,
  musicianTypes,
  languages,
  userRoles,
};

export * from './colors';
