import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import SidebarMenu from './SidebarMenu';

import styles from './styles';

function Sidebar(props) {

  const {
    classes,
    isDrawerOpen,
    toggleSidebar,
  } = props;

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={toggleSidebar}
      className={classNames(classes.drawer)}
      variant={!isMobile ? 'persistent' : 'temporary'}
      classes={{ paper: classes.drawerPaper, root: classNames(classes.root, { [classes.open]: isDrawerOpen }) }}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={toggleSidebar}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider/>
      <SidebarMenu
        onItemClick={isMobile && toggleSidebar}
      />
      <Divider/>
    </Drawer>
  );
}

Sidebar.propTypes = {
  isDrawerOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

export default withStyles(styles)(Sidebar);
