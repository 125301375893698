import React, { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';

import Table from 'components/Table';

import BaseSection from 'sections/BaseSection';

import { goToDetails } from 'helpers/router/goToDetails';

import {
  findLocale,
  searchWithLocales,
  addImageProportions,
} from 'helpers';

import {
  FETCH_MUSICIANS,
  DELETE_MUSICIAN,
} from '_graphql/actions/musicians';

import { languages } from '_constants';

const orderTypes = [
  {
    label: 'Natural',
    value: 'NATURAL',
  },
  {
    label: 'Created At ASC',
    value: 'CREATED_AT_ASC',
  },
  {
    label: 'Created At DESC',
    value: 'CREATED_AT_DESC',
  },
  {
    label: 'Published At ASC',
    value: 'PUBLISHED_AT_ASC',
  },
  {
    label: 'Published At DESC',
    value: 'PUBLISHED_AT_DESC',
  },
  {
    label: 'Updated At ASC',
    value: 'UPDATED_AT_ASC',
  },
  {
    label: 'Updated At DESC',
    value: 'UPDATED_AT_DESC',
  },
];

const defaultOrderType = 'CREATED_AT_DESC';

const defaultVariables = {
  orderBy: defaultOrderType,
};

const tableColumns = [
  {
    title: 'Photo',
    getValue: item => item.photo
      ? <Avatar src={addImageProportions(item.photo.url, 80, 80)}/>
      : '',
  },
  {
    title: 'Path (url)',
    name: 'path'
  },
  {
    title: 'Musician Type',
    name: 'type',
  },
  {
    title: 'Published',
    getValue: item => item.published ? 'yes' : 'no',
  },
  ...languages.map(lang => (
    {
      title: 'Title ' + lang.code,
      getValue: item => {
        const { firstName = '', lastName = '' } = findLocale(item.locales, lang.code);
        return `${firstName} ${lastName}`;
      },
    }
  )),
];

function Musicians(props) {
  const [deleteArticle, { loading: deleteLoading }] = useMutation(DELETE_MUSICIAN);

  const {
    history,
  } = props;

  const handleAddButtonClick = useCallback(row => {
    goToDetails(history, 'musician')(row);
  }, [history]);

  const handleDelete = useCallback(musicianId => {
    return deleteArticle({
      variables: {
        id: musicianId,
      },
      refetchQueries: ['FetchMusicians'],
    });
  }, [deleteArticle]);

  return (
    <BaseSection
      title="Musicians"
      isLoading={deleteLoading}
      onAddButtonClick={handleAddButtonClick}
    >
      <Table
        dataKey="musicians"
        onDelete={handleDelete}
        orderOptions={orderTypes}
        tableColumns={tableColumns}
        fetchQuery={FETCH_MUSICIANS}
        onEdit={handleAddButtonClick}
        onRowFilter={searchWithLocales}
        defaultVariables={defaultVariables}
        defaultOrderType={defaultOrderType}
        onDoubleClick={handleAddButtonClick}
      />
    </BaseSection>
  );

}

export default withRouter(Musicians);
