import React from 'react';

import SimpleDataSection from 'containers/SimpleDataSection';

import {
  DELETE_PROFESSION,
  FETCH_PROFESSIONS,
  ADD_EMPTY_PROFESSION,
  ADD_PROFESSION_LOCALE,
  UPDATE_PROFESSION_LOCALE,
} from '_graphql/actions/professions';

function Professions() {
  return (
    <SimpleDataSection
      dataKey="profession"
      sectionTitle="Professions"
      queryName="FetchProfessions"
      fetchQuery={FETCH_PROFESSIONS}
      addQuery={ADD_EMPTY_PROFESSION}
      deleteQuery={DELETE_PROFESSION}
      createActionKey="createProfession"
      addLocaleQuery={ADD_PROFESSION_LOCALE}
      updateLocaleQuery={UPDATE_PROFESSION_LOCALE}
    />
  );
}

export default Professions;
