import ContactMessages from './index';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/contact-messages',
    title: 'Contact Messages',
    component: ContactMessages,
  },
];

export default routes;
