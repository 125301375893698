import PageSections from './index';
import PageSectionDetails from './PageSectionDetails';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/page-sections',
    title: 'Page Sections',
    component: PageSections,
  },
  {
    path: '/page-sections/details/:name?/:page?',
    title: 'Page Sections Details',
    component: PageSectionDetails,
  },
];

export default routes;
