import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import UserMenu from './UserMenu';
import styles from './styles';

function Header(props) {
  const {
    classes,
    toggleSidebar,
  } = props;

  return (
    <AppBar
      color="default"
      position="absolute"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          onClick={toggleSidebar}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          noWrap
          variant="h6"
          component="h1"
          color="inherit"
          className={classes.title}
        >
          ANM Admin
        </Typography>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  toggleSidebar: PropTypes.func,
};

export default withStyles(styles)(Header);
