import Musicians from './index';
import MusicianDetails from './MusicianDetails';
import MusicNoteIcon from '@material-ui/icons/MusicNote';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/musicians',
    title: 'Musicians',
    icon: MusicNoteIcon,
    component: Musicians,
  },
  {
    exact: true,
    path: '/musician/details/:musicianId?',
    component: MusicianDetails,
  },
];

export default routes;
