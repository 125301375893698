import { gql } from 'apollo-boost';

import { genreAll, genreLocaleAll } from '_graphql/fragments';

export const FETCH_GENRES = gql`
  query FetchGenres($limit: Int, $offset: Int) {
    genres(first: $limit, offset: $offset) {
      nodes {
        ...genreAll
        locales: genreLocalesBySourceIdList {
          ...genreLocaleAll
        }
      }
      totalCount
    }
  }

  ${genreAll}
  ${genreLocaleAll}
`;

export const ADD_EMPTY_GENRE = gql`
  mutation AddEmptyGenre {
    createGenre(input: { genre: { } }) {
      genre {
        ...genreAll
      }
    }
  }

  ${genreAll}
`;

export const ADD_GENRE_LOCALE = gql`
  mutation CreateGenreLocale($id: Int!, $name: String, $lang: String!) {
    createGenreLocale(input: {
      genreLocale: {
        lang: $lang
        name: $name
        sourceId: $id
      }}) {
      locale: genreLocale {
        ...genreLocaleAll
      }
    }
  }
  ${genreLocaleAll}
`;

export const UPDATE_GENRE_LOCALE = gql`
  mutation UpdateGenreLocale($id: Int!, $name: String, $lang: String!) {
    updateGenreLocale(
      input: {
        patch: {
          name: $name
          sourceId: $id
          lang: $lang
        },
        sourceId: $id
        lang: $lang
      }
    ) {
      locale: genreLocale {
        ...genreLocaleAll
      }
    }
  }
  ${genreLocaleAll}
`;

export const DELETE_GENRE = gql`
  mutation DeleteGenre($id: Int!) {
    deleteGenre(input: { id: $id }) {
      genre {
        id
        nodeId
      }
    }
  }
`;
