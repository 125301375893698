import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import formatDate from 'dateformat';
import { Select } from 'components/Form';
import { searchInValues } from 'helpers';

import withUser from 'hoc/withUser';

import Table from 'components/Table';
import BaseSection from 'sections/BaseSection';

import { userRoles } from '_constants';

import {
  FETCH_USERS,
  DELETE_USER,
  CHANGE_USER_ROLE,
} from '_graphql/actions';
import { useSearchParams } from 'hooks/useSearchParams';

const userRoleOptions = userRoles.map(r => ({
  value: r.role,
  label: r.title,
}));

const orderTypes = [
  {
    label: 'Natural',
    value: 'NATURAL',
  },
  {
    label: 'Created At ASC',
    value: 'CREATED_AT_ASC',
  },
  {
    label: 'Created At DESC',
    value: 'CREATED_AT_DESC',
  },
  {
    label: 'Email ASC',
    value: 'EMAIL_ASC',
  },
  {
    label: 'Email DESC',
    value: 'EMAIL_DESC',
  },
  {
    label: 'Updated At ASC',
    value: 'UPDATED_AT_ASC',
  },
  {
    label: 'Updated At DESC',
    value: 'UPDATED_AT_DESC',
  },
];

const defaultOrderType = 'CREATED_AT_DESC';

const defaultVariables = {
  orderBy: defaultOrderType,
};

function Users(props) {
  const {
    isAdmin,
  } = props;

  const {
    id,
    email,
    filter,
    lastName,
    firstName,
  } = useSearchParams();

  const defaultFilterBy = (email || filter) || lastName || firstName || id;

  const [deleteUser, { loading: isDeleteLoading }] = useMutation(DELETE_USER);
  const [changeUserRole, { loading: isRoleChangeLoading }] = useMutation(CHANGE_USER_ROLE);

  const handleUserDelete = useCallback(async userId => {
    if (isAdmin) {
      deleteUser({
        variables: {
          id: userId,
        },
        refetchQueries: ['FetchUsers'],
      });
    }
  }, [isAdmin, deleteUser]);

  const handleRoleChange = useCallback((option, user) => {
    if (option.value === user.role) return;

    if (
      isAdmin
      && window.confirm(`Are you sure that you want to change ${user.name}'s role from ${user.role} to ${option.value} ?`)
    ) {
      changeUserRole({
        variables: {
          id: user.id,
          role: option.value,
        },
        refetchQueries: ['FetchUsers'],
      });
    }
  }, [isAdmin, changeUserRole]);

  const tableColumns = useMemo(() => {
    return [
      {
        title: 'Created On',
        getValue(item) {
          return formatDate(item.createdAt, 'dd-mm-yyyy hh:ss');
        },
      },
      {
        title: 'First Name',
        name: 'firstName',
      },
      {
        title: 'Last Name',
        name: 'lastName',
      },
      {
        title: 'Email Name',
        name: 'email',
      },
      {
        title: 'Role',
        getValue(user) {
          return isAdmin
            ? (
              <Select
                value={user.role}
                options={userRoleOptions}
                onChange={(_, _2, { val }) => handleRoleChange(val, user)}
              />
            ) : user.role;
        },
      },
    ]
  }, [isAdmin, handleRoleChange]);

  return (
    <BaseSection
      title="Users"
      isLoading={isDeleteLoading || isRoleChangeLoading}
    >
      <Table
        dataKey="users"
        fetchQuery={FETCH_USERS}
        orderOptions={orderTypes}
        tableColumns={tableColumns}
        onRowFilter={searchInValues}
        defaultVariables={defaultVariables}
        defaultOrderType={defaultOrderType}
        defaultFilterValue={defaultFilterBy}
        onDelete={isAdmin && handleUserDelete}
      />
    </BaseSection>
  );
}

Users.defaultProps = {};

Users.propTypes = {};

export default withUser(Users);
