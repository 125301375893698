import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

import { ToastsStore } from 'react-toasts';

export default new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            ToastsStore.error(message);
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          }
        );
      if (networkError) ToastsStore.error(`[Network error]: ${networkError}`);
    }),
    createUploadLink({
      uri: process.env.REACT_APP_GRAPH_API,
      credentials: 'include',
    })
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: node => node.nodeId || node.id,
  }),
});
