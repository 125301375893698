export const colors = {
  'primary-hex': '#005CDB',
  'primary-blue': 'rgb(0, 92, 219)',
  'secondary-blue': '#4CAAEB',
  'primary-blue-75': 'rgba(0, 92, 219, .75)',
  'primary-blue-50': 'rgba(0, 92, 219, .5)',
  'primary-blue-25': 'rgba(0, 92, 219, .25)',
  'light-grey': '#F8F8F8',
  'text-color': '#252525',
  'secondary-text-color': 'rgba(37, 37, 37, .5)',
  'orange': '#F6AB11',
  'green': '#1BB355',
  'red': '#E73535',
};

export const colorsList = [
  colors['primary-blue'],
  colors['secondary-blue'],
  colors['primary-blue-75'],
  colors['primary-blue-50'],
  colors['primary-blue-25'],
  colors['light-grey'],
  colors['text-color'],
  colors['orange'],
  colors['green'],
  colors['red'],
];
