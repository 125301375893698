import React from 'react';
import PropTypes from 'prop-types';
import {
  ToastsContainerPosition,
  ToastsContainer,
  ToastsStore,
} from 'react-toasts';

import { ApolloProvider } from '@apollo/react-hooks';
import client from '_graphql';

import {
  BrowserRouter,
} from 'react-router-dom';

import MaterialThemeProvider from 'theme/MaterialThemeProvider';
import AuthProvider from 'pages/Auth/AuthProvider';

import { withStyles } from '@material-ui/core/styles';
import MainApp from 'sections/MainApp';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <MaterialThemeProvider>
        <BrowserRouter>
          <AuthProvider>
            <MainApp/>
          </AuthProvider>
        </BrowserRouter>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
      </MaterialThemeProvider>
    </ApolloProvider>
  );
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
