import React from 'react';
import { TextField } from 'components/Form';
import dateFormat from 'dateformat';

const format = 'yyyy-mm-dd';

export const renderFormField = options => {
  const {
    inp,
    formData,
    lang = {},
    onFormChange,
    fullWidth = true,
    margin = 'normal',
    variant = 'outlined',
  } = options;

  const InpComp = inp.component || TextField;

  const value = formData[inp.name];

  const formattedValue = inp.props && inp.props.type === 'date'
    ? dateFormat(value, format)
    : String(value) === '0'
      ? '0'
      : value || '';

  const inpProps = {
    margin,
    variant,
    fullWidth,
    name: inp.name,
    label: inp.label,
    required: !!inp.required,
    dataLang: lang.code || '',
    value: formattedValue,
    onChange: onFormChange,
    ...(inp.props || {}),
  };

  return (
    <InpComp
      key={inp.name}
      {...inpProps}
    />
  );
};
