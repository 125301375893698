import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { languages } from '_constants';

import Flags from 'images/flags';

import styles from './styles';

function LocaleTabs(props) {
  const [activeTab, setActiveTab] = useState(languages[0].code);
  const {
    classes,
    renderContent,
  } = props;

  const currentLang = languages.find(lang => lang.code === activeTab);

  const handleTabChange = useCallback((event, tabValue) => {
    setActiveTab(tabValue);
  }, []);

  return (
    <>
      <Tabs
        value={activeTab}
        variant="fullWidth"
        indicatorColor="primary"
        onChange={handleTabChange}
        className={classNames(classes.tabs, { [classes.sticky]: props.sticky })}
      >
        {languages.map(language => {
          return (
            <Tab
              key={language.code}
              label={(
                <div className={classes.tab}>
                  <img
                    className={classes.flagImage}
                    src={Flags[`${language.code}Flag`]}
                    alt={language.name}
                  />
                  {language.name}
                </div>
              )}
              value={language.code}
            />
          );
        })}
      </Tabs>
      <div>
        {renderContent(currentLang)}
      </div>
    </>
  );
}

LocaleTabs.defaultProps = {
  sticky: false,
};

LocaleTabs.propTypes = {
  renderContent: PropTypes.func.isRequired,
};

export default withStyles(styles)(LocaleTabs);
