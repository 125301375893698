export default theme => ({
  mainList: {
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  collapseHeader: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(),
  },
  sidebarCollapsableItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
});
