import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import { FormSection } from 'components/Form';

import dateFormat from 'dateformat';

const format = 'yyyy-mm-dd';

function PublishRow(props) {
  const {
    onUpdate,
    published,
    publishedAt,
  } = props;

  const handlePublishUpdate = useCallback(publishStatus => {
    onUpdate({
      published: publishStatus,
      publishedAt: new Date().toISOString(),
    });
  }, [onUpdate]);

  const handleManualDateChange = useCallback(event => {
    const { value } = event.target;

    if (window.confirm('Are you sure that you want to set Published date manually?')) {
      if (!value) {
        // Remove published status
        handlePublishUpdate(false);
      } else {
        onUpdate({
          publishedAt: dateFormat(value, format),
        });
      }
    }
  }, [onUpdate, handlePublishUpdate]);

  return (
    <FormSection title="Status">
      <div className="margin-top-15">
        {[
          {
            onClick: () => handlePublishUpdate(false),
            className: 'margin-right-15',
            children: 'Unpublish',
            disabled: !published,
          },
          {
            onClick: () => handlePublishUpdate(true),
            className: 'margin-right-15',
            children: 'Publish',
            color: 'primary',
          }].map(btn => <Button key={btn.children} {...btn}/>)}

        <Input
          type="date"
          onChange={handleManualDateChange}
          value={publishedAt ? dateFormat(publishedAt, format) : ''}
        />

        <div className="margin-top-15">
          {published
            ? (
              <p>
                Last published on:
                <br/>
                <b>{`${new Date(publishedAt)}`}</b>
              </p>
            )
            : 'Not Published yet'}
        </div>
      </div>
    </FormSection>
  );
}

PublishRow.defaultProps = {};

PublishRow.propTypes = {
  published: PropTypes.bool,
  publishedAt: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

export default PublishRow;
