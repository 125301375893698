import { gql } from 'apollo-boost';

import { instrumentAll, instrumentLocaleAll } from '_graphql/fragments/instruments';

export const FETCH_INSTRUMENTS = gql`
  query FetchInstruments($limit: Int, $offset: Int) {
    instruments(first: $limit, offset: $offset) {
      nodes {
        ...instrumentAll
        locales: instrumentLocalesBySourceIdList {
          ...instrumentLocaleAll
        }
      }
      totalCount
    }
  }

  ${instrumentAll}
  ${instrumentLocaleAll}
`;

export const ADD_EMPTY_INSTRUMENT = gql`
  mutation AddEmptyInstrument {
    createInstrument(input: { instrument: { } }) {
      instrument {
        ...instrumentAll
      }
    }
  }

  ${instrumentAll}
`;

export const ADD_INSTRUMENT_LOCALE = gql`
  mutation CreateInstrumentLocale($id: Int!, $name: String, $lang: String!) {
    createInstrumentLocale(input: {
      instrumentLocale: {
        lang: $lang
        name: $name
        sourceId: $id
      }}) {
      locale: instrumentLocale {
        ...instrumentLocaleAll
      }
    }
  }
  ${instrumentLocaleAll}
`;

export const UPDATE_INSTRUMENT_LOCALE = gql`
  mutation UpdateInstrumentLocale($id: Int!, $name: String, $lang: String!) {
    updateInstrumentLocale(
      input: {
        patch: {
          name: $name
          sourceId: $id
          lang: $lang
        },
        sourceId: $id
        lang: $lang
      }
    ) {
      locale: instrumentLocale {
        ...instrumentLocaleAll
      }
    }
  }
  ${instrumentLocaleAll}
`;

export const DELETE_INSTRUMENT = gql`
  mutation DeleteInstrument($id: Int!) {
    deleteInstrument(input: { id: $id }) {
      instrument {
        id
        nodeId
      }
    }
  }
`;
