import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Loading from 'components/Loading';
import Form, {
  FormSection,
} from 'components/Form';

import Grid from '@material-ui/core/Grid';

import LocaleTabs from 'components/LocaleTabs';

import {
  findLocale,
  renderFormField,
} from 'helpers';

import {
  FETCH_SCORE_LOCALES,
  CREATE_SCORE_LOCALE,
  UPDATE_SCORE_LOCALE,
} from '_graphql/actions';

const fields = [
  {
    name: 'title',
    required: true,
    label: 'Score Title',
  },
  {
    required: true,
    name: 'description',
    label: 'Score Description',
    props: {
      rows: 5,
      multiline: true,
    },

  },
];

function ScoreLocales(props) {
  const { scoreId } = props;
  const { data: { score = {} } = {}, loading } = useQuery(FETCH_SCORE_LOCALES, {
    variables: {
      id: scoreId,
    },
  });

  const [createScoreLocale, { loading: crtLoading }] = useMutation(CREATE_SCORE_LOCALE);
  const [updateScoreLocale, { loading: updLoading }] = useMutation(UPDATE_SCORE_LOCALE);

  const handleFormSubmit = useCallback(locale => {
    const action = locale.nodeId
      ? updateScoreLocale
      : createScoreLocale;

    return action({
      variables: {
        scoreId: scoreId,
        ...locale,
      },
      refetchQueries: ['FetchScores'],
    });

  }, [updateScoreLocale, createScoreLocale, scoreId]);

  return (
    <Loading
      isLoading={loading || crtLoading || updLoading}
    >
      <LocaleTabs
        renderContent={lang => {
          const locale = findLocale(score.locales, lang.code);

          return (
            <Form
              key={lang.code}
              title="Score Info"
              defaultData={locale}
              isEdit={!!locale.nodeId}
              onSubmit={handleFormSubmit}
              renderContent={(onFormChange, formData) => {
                return (
                  <FormSection>
                    <Grid container>
                      {fields.map(inp => renderFormField({ inp, formData, onFormChange, lang }))}
                    </Grid>
                  </FormSection>
                );
              }}
            />
          );
        }}
      />
    </Loading>
  );
}

ScoreLocales.defaultProps = {};

ScoreLocales.propTypes = {
  scoreId: PropTypes.number.isRequired,
};

export default ScoreLocales;
