import React from 'react';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Loading from 'components/Loading';
import FormActions from 'components/Form/FormActions';
import FormHeader from 'components/Form//FormHeader';

import { discardMessage } from '_constants';

const styles = {
  footer: {
    borderTop: '2px solid rgba(0, 0, 0, .07)',
  },
  content: {
    overflow: 'auto',
    position: 'relative',
  },
};

class Form extends React.Component {
  state = {
    formData: {},
    editedData: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.defaultData) && isEmpty(prevState.formData) && nextProps.defaultData !== prevState.formData) {
      return {
        formData: cloneDeep(nextProps.defaultData),
      };
    }

    return null;
  }

  setFormData = formData => {
    this.setState({
      formData,
    });
  };

  setEditedData = editedData => {
    this.setState({
      editedData,
    });
  };

  handleFormSubmit = () => {
    this.props.onSubmit(cloneDeep(this.state.formData));
  };

  handleFormChange = (data, name, { value, dataLang }) => {
    let dataCopy = cloneDeep(this.state.formData);
    let editDataCopy = cloneDeep(this.state.editedData);

    if (dataLang) {
      dataCopy[name] = value;
      editDataCopy[name] = value;
      dataCopy.lang = dataLang;
    } else {
      dataCopy = {
        ...dataCopy,
        ...(data || {}),
      };

      editDataCopy = {
        ...editDataCopy,
        ...(data || {}),
      };
    }

    this.setFormData(dataCopy);
    this.setEditedData(editDataCopy);
  };

  hasChanges = (formData, defaultData) => {
    return !isEqual(formData, defaultData);
  };

  handleFormCancel = () => {
    const {
      onCancel,
    } = this.props;

    // prevent update date change to be checked
    if (this.hasChanges(this.state.formData, this.props.defaultData)) {
      if (window.confirm(discardMessage)) {
        onCancel();
      }
    } else {
      onCancel();
    }
  };

  render() {
    const {
      formData,
      editedData,
    } = this.state;

    const {
      formAdditionalButtons,
      cancelButtonText,
      saveButtonText,
      renderContent,
      withFooter,
      isLoading,
      disabled,
      onCancel,
      classes,
      isEdit,
      title,
    } = this.props;

    return (
      <Loading
        isLoading={isLoading}
      >
        {title && (
          <FormHeader
            canSave
            title={title}
            isEdit={isEdit}
          />
        )}

        <div
          className={classes.content}
        >
          {renderContent(this.handleFormChange, formData, editedData)}

          {withFooter && (
            <FormActions
              disabled={disabled}
              saveButtonText={saveButtonText}
              onSubmit={this.handleFormSubmit}
              cancelButtonText={cancelButtonText}
              additionalButtons={formAdditionalButtons}
              onCancel={onCancel && this.handleFormCancel}
            />
          )}
        </div>
      </Loading>
    );
  }
}

Form.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  withFooter: PropTypes.bool,
  allowEmptySave: PropTypes.bool,
  defaultData: PropTypes.shape({}),
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
};

Form.defaultProps = {
  title: '',
  onCancel: null,
  disabled: false,
  isLoading: false,
  withFooter: true,
  allowEmptySave: false,
  formAdditionalButtons: [],
};

export default withStyles(styles)(Form);
