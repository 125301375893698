import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import GoBackPageButtonRow from 'components/GoBackPageButtonRow';
import Form, {
  FormSection,
} from 'components/Form';

import PublishRow from 'sections/PublishRow';
import ContentSection from 'sections/ContentSection';

import withUser from 'hoc/withUser';

import CompositionLocales from './CompositionLocales';

import {
  ADD_COMPOSITION,
  UPDATE_COMPOSITION,
  FETCH_SINGLE_COMPOSITION,
} from '_graphql/actions/compositions';

function CompositionsDetails(props) {
  const {
    history,
    match: { params },
  } = props;
  
  const [composition, setCompositionData] = useState({});
  const [fetchSingleComposition, { loading }] = useLazyQuery(FETCH_SINGLE_COMPOSITION, {
    onCompleted: ({ composition }) => {
      setCompositionData(composition);
    },
  });

  const [addComposition, { loading: addCompositionLoading }] = useMutation(ADD_COMPOSITION, {
    onCompleted: ({ createComposition: { composition: newComposition } }) => {
      setCompositionData(newComposition);
      history.replace(`/composition/details/${newComposition.id}`);
    },
  });

  const [updateComposition, { loading: updateCompositionLoading }] = useMutation(UPDATE_COMPOSITION);
  
  const fetchComposition = useCallback(() => {
    fetchSingleComposition({
      variables: {
        id: Number(params.compositionId),
      },
    });
  }, [fetchSingleComposition, params]);
  
  useEffect(() => {
    if (params.compositionId) {
      fetchComposition();
    }
  }, [fetchComposition, params]);

  const isEdit = !!(composition && composition.id);

  const handleFormSubmit = useCallback(async newData => {
    const action = isEdit
      ? updateComposition
      : addComposition;

    return action({
      variables: newData,
      refetchQueries: ['FetchCompositions'],
    });
  }, [updateComposition, addComposition, isEdit]);

  const handleCompositionPublish = useCallback(publishData => {
    return updateComposition({
      variables: {
        id: composition.id,
        ...publishData
      },
    });
  }, [updateComposition, composition]);

  const isLoading = loading
    || updateCompositionLoading
    || addCompositionLoading;

  return (
    <ContentSection
      isLoading={isLoading}
    >
      <GoBackPageButtonRow
        goTo="/compositions"
      />

      {composition.id && (
        <PublishRow
          published={composition.published}
          onUpdate={handleCompositionPublish}
          publishedAt={composition.publishedAt}
        />
      )}

      {composition.id && (
        <div>
          <CompositionLocales
            compositionId={composition.id}
          />
        </div>
      )}

      {!composition.id && (
        <Form
          isEdit={isEdit}
          title="Details"
          isLoading={isLoading}
          defaultData={composition}
          onSubmit={handleFormSubmit}
          renderContent={() => {
            return (
              <FormSection>
                <h4>Click save to create an empty composition</h4>
              </FormSection>
            );
          }}
        />
      )}

    </ContentSection>
  );
}

export default withUser(CompositionsDetails);
