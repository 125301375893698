import { gql } from 'apollo-boost';

export const CREATE_ARTICLE_GALLERY = gql`
  mutation CreateArticleGallery($id: Int!) {
    createArticleGallery(input: {
      articleId: $id
    }) {
      articleGallery {
        id
        nodeId
      }
    }
  }
`;

export const FETCH_ARTICLE_GALLERY = gql`
  query FetchArticleGallery($galleryId: Int!) {
    articleGallery(id: $galleryId) {
      id
      nodeId
      images: imagesList {
        id
        url
        nodeId
      }
    }
  }
`;

export const ADD_ARTICLE_GALLERY_IMAGE = gql`
  mutation AddArticleGalleryImage(
    $imageId: Int!
    $galleryId: Int!
  ) {
    createArticleGalleryImage(input: {
      articleGalleryImage: {
        imageId: $imageId
        galleryId: $galleryId
      }
    }) {
      image {
        id
        url
        nodeId
      }
    }
  }
`;

export const DELETE_ARTICLE_GALLERY_IMAGE = gql`
  mutation DeleteArticleGalleryImage(
    $galleryId: Int!
    $imageId: Int!
  ) {
    deleteArticleGalleryImage(input: {
      galleryId: $galleryId
      imageId: $imageId
    }) {
      image {
        id
        url
        nodeId
      }
    }
  }
`;
