import { gql } from 'apollo-boost';
import { imageAll } from '_graphql/fragments/images';
import { genreAll } from '_graphql/fragments/genres';
import { tagAll } from '_graphql/fragments/tags';

export const articleLocalesAll = gql`
  fragment articleLocalesAll on ArticleLocale {
    lang
    title
    nodeId
    content
    description
  }
`;

export const articleMain = gql`
  fragment articleMain on Article {
    id
    nodeId
    path
    createdAt
    updatedAt
    posterId
    authorId
    galleryId
    published
    publishedAt
  }
`;

export const articleAll = gql`
  fragment articleAll on Article {
    ...articleMain
    author {
      id
      nodeId
      lastName
      firstName
      email
    }
    images: imagesList {
      ...imageAll
    }
    tags: tagsList {
      ...tagAll
    }
    genres: genresList {
      ...genreAll
    }
    poster {
      id
      url
    }
    gallery {
      images: imagesList {
        ...imageAll
      }
    }
    locales: articleLocalesBySourceIdList {
      ...articleLocalesAll
    }
  }

  ${articleMain}
  ${tagAll}
  ${genreAll}
  ${imageAll}
  ${articleLocalesAll}
`;
