export default {
  loginWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  row: {
    marginBottom: 10,
    width: '100%',
  },
  form: {
    width: 500,
  },
};
