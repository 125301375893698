import { gql } from 'apollo-boost';

export const genreLocaleAll = gql`
  fragment genreLocaleAll on GenreLocale {
    lang
    name
    nodeId
  }
`;

export const genreAll = gql`
  fragment genreAll on Genre {
    id
    nodeId
    locales: genreLocalesBySourceIdList {
      ...genreLocaleAll
    }
  }
  
  ${genreLocaleAll}
`;
