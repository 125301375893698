import React from 'react';

function NotFound() {
  return (
    <div>
      <h4>Not Found</h4>
    </div>
  )
}

export default NotFound;
