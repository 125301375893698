import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RootRef from '@material-ui/core/RootRef';

import AddFabButton from 'components/AddFabButton';
import Paper from 'components/Paper';

import { noop } from 'helpers';

const styles = {
  paper: {
    cursor: 'pointer',
    border: '2px dashed transparent',
    '&:hover': {
      border: '2px dashed lightgray',
    },
  },
};

function DropZone (props) {
  const {
    onFileUpload,
    onRequest,
    multiple,
    classes,
    title,
    accept,
  } = props;

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop: onUpload,
    multiple,
    accept,
  });

  function onUpload (files) {
    const hasMultipleFiles = multiple && files.length > 1;
    const uploadedData = hasMultipleFiles ? files : files[0];

    if (hasMultipleFiles) {
      uploadedData.forEach(file => {
        onSubmit({}, file, null);
      });
    } else {
      onSubmit({}, uploadedData, null);
    }
  }

  async function onSubmit (imageData, uploadedFile, image) {
    if (onRequest) {
      return await onRequest(uploadedFile, imageData);
    } else {
      return onFileUpload(uploadedFile, imageData, image);
    }
  }

  const {
    ref,
    ...rootProps
  } = getRootProps();

  const {
    multiple: inputMultiple,
    ...inputProps
  } = getInputProps();

  return (
    <RootRef rootRef={ref}>
      <Paper {...rootProps} className={classes.paper}>
        <input {...inputProps} />
        <Typography variant="h6">
          <AddFabButton /> {title}
        </Typography>
      </Paper>
    </RootRef>
  );
}

DropZone.defaultProps = {
  accept: 'image/*',
  multiple: false,
  withRequest: true,
  onFileUpload: noop,
  onFileRemove: noop,
  title: 'Click or drag files here',
};

DropZone.propTypes = {
  onFileUpload: PropTypes.func,
  onFileRemove: PropTypes.func,
  withRequest: PropTypes.bool,
  multiple: PropTypes.bool,
  title: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default withStyles(styles)(DropZone);

