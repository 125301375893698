// TODO: Refactor
import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';

import {
  useMutation,
  useLazyQuery,
} from '@apollo/react-hooks';

import { musicianTypes } from '_constants';

import { getSelectInputData } from 'helpers/array/getSelectInputData';
import { renderFormField } from 'helpers/components/renderFormField';

import Form, {
  Select,
  FormSection,
} from 'components/Form';
import PathInput from 'components/PathInput';
import GoBackPageButtonRow from 'components/GoBackPageButtonRow';

import ProfessionsSelect from 'containers/ProfessionsSelect';
import CompositionsSelect from 'containers/CompositionsSelect';

import PublishRow from 'sections/PublishRow';
import ContentSection from 'sections/ContentSection';

import {
  checkPagePath,
} from 'helpers/text/checkPagePath';

import {
  FETCH_MUSICIANS,
  CREATE_MUSICIAN,
  UPDATE_MUSICIAN,
  FETCH_SINGLE_MUSICIAN,
  CREATE_MUSICIAN_PROFESSION,
  DELETE_MUSICIAN_PROFESSION,
  CREATE_MUSICIAN_COMPOSITION,
  DELETE_MUSICIAN_COMPOSITION,
} from '_graphql/actions/musicians';

import withUser from 'hoc/withUser';

import MusicianLocales from './MusicianLocales';
import MusicianImages from './MusicianImages';

const defaultFields = [
  {
    name: 'birthday',
    label: 'Birth Day',
    props: {
      type: 'date',
    },
  },
  {
    name: 'deathday',
    label: 'Death Day',
    props: {
      type: 'date',
    },
  },
];

function MusicianDetails(props) {
  const {
    isAdmin,
    match: { params },
  } = props;
  
  const { musicianId } = params;
  
  const [musician, setMusicianData] = useState({});
  const [fetchSingleMusician, { loading }] = useLazyQuery(FETCH_SINGLE_MUSICIAN, {
    onCompleted: ({ musician }) => {
      musician.compositions = getSelectInputData(musician.compositions);
      musician.professions = getSelectInputData((musician.professions || []).map(el => el.profession));
      setMusicianData(musician);
    },
  });
  const [createComposition, { loading: isCreateCompositionLoading }] = useMutation(CREATE_MUSICIAN_COMPOSITION);
  const [deleteComposition, { loading: isDeleteCompositionLoading }] = useMutation(DELETE_MUSICIAN_COMPOSITION);
  
  
  const [createProfession, { loading: isCreateProfessionLoading }] = useMutation(CREATE_MUSICIAN_PROFESSION);
  const [deleteProfession, { loading: isDeleteProfessionLoading }] = useMutation(DELETE_MUSICIAN_PROFESSION);
  
  const [createMusician, { loading: isCreateLoading }] = useMutation(CREATE_MUSICIAN, {
    onCompleted: ({ createMusician: { musician: newMusician } }) => {
      setMusicianData(newMusician);
      props.history.replace(`/musician/details/${newMusician.id}`);
    },
  });
  
  const [updateMusician, { loading: isUpdateLoading }] = useMutation(UPDATE_MUSICIAN);
  
  const fetchMusician = useCallback(() => {
    fetchSingleMusician({
      variables: {
        id: Number(musicianId),
      },
    });
  }, [fetchSingleMusician, musicianId]);
  
  useEffect(() => {
    if (!musician.id && musicianId) {
      fetchMusician();
    }
  }, [fetchMusician, musician, musicianId]);
  
  const handleFormSubmit = useCallback(async formData => {
    if (checkPagePath(formData.path)) {
      const action = formData.id
        ? updateMusician
        : createMusician;
      
      await action({
        variables: formData,
        refetchQueries: [
          { query: FETCH_MUSICIANS },
        ],
      });
    }
  }, [updateMusician, createMusician]);
  
  const handleMusicianPublish = useCallback(publishData => {
    return updateMusician({
      variables: {
        ...musician,
        ...publishData
      },
      refetchQueries: [
        { query: FETCH_MUSICIANS },
      ],
    });
  }, [musician, updateMusician]);
  
  const handleCompositionsChange = useCallback(async (selectedValue, valueKey, options) => {
    const { compositions } = musician;
    const compositionId = options.option.value;
    const isCompositionNew = !!compositions.find(id => id === compositionId);
    
    const action = !isCompositionNew
      ? createComposition
      : deleteComposition;
    
    const res = await action({
      variables: {
        compositionId,
        musicianId: musician.id,
      },
      refetchQueries: ['FetchMusicians'],
    });
    
    if (res && res.data) {
      const { createMusicianComposition, deleteMusicianComposition } = res.data;
      const { musician: updatedMusicianData } = (createMusicianComposition || deleteMusicianComposition);
  
      updatedMusicianData.compositions = getSelectInputData(updatedMusicianData.compositions);
      updatedMusicianData.professions = getSelectInputData((updatedMusicianData.professions || []).map(el => el.profession));
      setMusicianData(updatedMusicianData || musician);
    }
  }, [createComposition, deleteComposition, setMusicianData, musician]);
  
  const handleProfessionChange = useCallback(async (selectedValue, valueKey, options) => {
    const { professions } = musician;
    const professionId = options.option.value;
    const isProfessionNew = !!professions.find(id => id === professionId);
  
    const action = !isProfessionNew
      ? createProfession
      : deleteProfession;
  
    const res = await action({
      variables: {
        professionId,
        musicianId: musician.id,
      },
      refetchQueries: ['FetchMusicians'],
    });
  
    if (res && res.data) {
      const { createMusicianProfession, deleteMusicianProfession } = res.data;
      const { musician: updatedMusicianData } = (createMusicianProfession || deleteMusicianProfession);
    
      updatedMusicianData.compositions = getSelectInputData(updatedMusicianData.compositions);
      updatedMusicianData.professions = getSelectInputData((updatedMusicianData.professions || []).map(el => el.profession));
      setMusicianData(updatedMusicianData || musician);
    }
  }, [createProfession, deleteProfession, setMusicianData, musician]);
  
  const isLoading = loading
    || isCreateLoading
    || isUpdateLoading
    || isCreateCompositionLoading
    || isDeleteCompositionLoading
    || isCreateProfessionLoading
    || isDeleteProfessionLoading;
  
  return (
    <ContentSection
      isLoading={isLoading}
    >
      <GoBackPageButtonRow
        goTo="/musicians"
      />
      
      {musician.id && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <PublishRow
                published={musician.published}
                onUpdate={handleMusicianPublish}
                publishedAt={musician.publishedAt}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MusicianImages
                musician={musician}
                updateMusician={updateMusician}
              />
            </Grid>
          </Grid>
          <CompositionsSelect
            isMulti
            value={musician.compositions}
            onChange={handleCompositionsChange}
          />
          
          <ProfessionsSelect
            isMulti
            value={musician.professions}
            onChange={handleProfessionChange}
          />
        </>
      )}
      
      <Form
        defaultData={musician}
        onSubmit={handleFormSubmit}
        renderContent={(onFormChange, formData) => {
          return (
            <FormSection title="Required Info">
              <Grid
                container
                spacing={2}
                alignItems="center"
              >
                {defaultFields.map(inp => (
                  <Grid item key={inp.name}>
                    {renderFormField({
                      inp,
                      formData,
                      onFormChange,
                      fullWidth: false,
                    })}
                  </Grid>
                ))}
                
                <Grid item xs={4}>
                  <Select
                    required
                    name="type"
                    label="Musician Type"
                    value={formData.type}
                    options={musicianTypes}
                    onChange={onFormChange}
                  />
                </Grid>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <PathInput
                  onChange={onFormChange}
                  value={formData.path || ''}
                  disabled={!isAdmin && !!musician.id}
                />
              </Grid>
            </FormSection>
          );
        }}
      />
      
      {musician.id && (
        <MusicianLocales
          musicianId={musician.id}
        />
      )}
    </ContentSection>
  );
}

export default withUser(MusicianDetails);
