import Professions from './';

const routes = [
  {
    exact: true,
    isMain: true,
    title: 'Professions',
    path: '/professions',
    component: Professions,
  },
];

export default routes;
