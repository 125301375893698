import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import MUITablePagination from '@material-ui/core/TablePagination';

function TablePagination(props) {
  const {
    rowCount,
    fetchMore,
    rowLength,
    pagination,
  } = props;

  const {
    page,
    rowsPerPage,
  } = pagination;

  const handleDataChange = useCallback(key => (event, val) => {
   const value = typeof val === 'number' ? val : event.target.value;
    fetchMore({ [key]: value, page: key === 'rowsPerPage' ? 0 : value });
  }, [fetchMore]);

  return (
    <MUITablePagination
      page={page}
      component="div"
      count={rowLength}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowCount}
      onChangePage={handleDataChange('page')}
      onChangeRowsPerPage={handleDataChange('rowsPerPage')}
    />
  );
}

TablePagination.defaultProps = {
  rowLength: 0,
};

TablePagination.propTypes = {
  rowLength: PropTypes.number,
};

export default TablePagination;
