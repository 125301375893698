import { gql } from 'apollo-boost';

import { professionAll, professionLocaleAll } from '_graphql/fragments';

export const FETCH_PROFESSIONS = gql`
  query FetchProfessions($limit: Int, $offset: Int) {
    professions(first: $limit, offset: $offset) {
     nodes {
       ...professionAll
       locales: professionLocalesBySourceIdList {
         ...professionLocaleAll
       }
     }
      totalCount
    }
  }
  ${professionAll}
  ${professionLocaleAll}
`;

export const ADD_EMPTY_PROFESSION = gql`
  mutation AddEmptyProfession {
    createProfession(input: { profession: { } }) {
      profession {
        ...professionAll
      }
    }
  }
  ${professionAll}
`;

export const ADD_PROFESSION_LOCALE = gql`
  mutation CreateProfessionLocale($id: Int!, $name: String, $lang: String!) {
    createProfessionLocale(input: {
      professionLocale: {
        lang: $lang
        name: $name
        sourceId: $id
      }}) {
      locale: professionLocale {
        ...professionLocaleAll
      }
    }
  }
  ${professionLocaleAll}
`;

export const UPDATE_PROFESSION_LOCALE = gql`
  mutation UpdateProfessionLocale($id: Int!, $name: String, $lang: String!) {
    updateProfessionLocale(
      input: {
        patch: {
          name: $name
          sourceId: $id
          lang: $lang
        },
        sourceId: $id
        lang: $lang
      }
    ) {
      locale: professionLocale {
        ...professionLocaleAll
      }
    }
  }
  ${professionLocaleAll}
`;

export const DELETE_PROFESSION = gql`
  mutation DeleteProfession($id: Int!) {
    deleteProfession(input: { id: $id }) {
      profession {
        id
        nodeId
      }
    }
  }
`;
