import PromoCodes from './index';
import PromoCodeDetails from './PromoCodeDetails';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/promo-codes',
    title: 'Promo Codes',
    component: PromoCodes,
  },
  {
    path: '/promo-code/details/:promoCodeId?',
    title: 'Promo Code Details',
    component: PromoCodeDetails,
  },
];

export default routes;
