import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParams = (searchParam) => {
  const { search } = useLocation();

  const searchParamToCheck = searchParam || search;

  return useMemo(() => {
    const res = {};
    const searchObj = new URLSearchParams(searchParamToCheck);

    for (const [key, value] of searchObj) {
      res[key] = value;
    }

    return res;
  }, [searchParamToCheck]);
};