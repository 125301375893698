import Articles from './index';
import ArticlesDetails from './ArticlesDetails';
import CallToActionIcon from '@material-ui/icons/CallToAction';
const routes = [
  {
    exact: true,
    isMain: true,
    path: '/articles',
    title: 'Articles',
    component: Articles,
    icon: CallToActionIcon,
  },
  {
    exact: true,
    path: '/articles/details/:articleId?',
    component: ArticlesDetails,
  },
];

export default routes;
