export const addImageProportions = (url, width, height) => {
  if (!url || (!width && !height)) {
    return url;
  }

  let newUrl = url;
  const widthParam = width ? `width=${width}` : '';
  const heightParam = height ? `height=${height}` : '';
  const urlParams = `${widthParam}&${heightParam}`;
  const isUrlWithParams = url.indexOf('?') !== -1;

  newUrl += isUrlWithParams ? `&${urlParams}` : `?${urlParams}`;

  return newUrl;
};
