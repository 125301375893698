import React, { useState, useCallback, useMemo } from 'react';
import get from 'lodash.get';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import MUITable from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';

import Loading from 'components/Loading';
import EditButton from 'components/EditButton';
import DeleteButton from 'components/DeleteButton';

import TablePagination from './TablePagination';

import {
  noop,
} from 'helpers';

import TableOrderSelect from './TableOrderSelect';

import {
  tableColumnsType,
} from './propTypes';

import useStyles from './styles';

const rowCount = [
  10,
  25,
  50,
];

const defaultPagination = {
  page: 0,
  rowsPerPage: rowCount[0],
};

/**
 * The main table for reproducing Musicians (for current they are performers and composers)
 * @param props
 * @returns {*}
 * @constructor
 */
function Table(props) {
  const {
    title,
    onEdit,
    dataKey,
    onDelete,
    onRowClick,
    fetchQuery,
    onRowFilter,
    tableColumns,
    orderOptions,
    onDoubleClick,
    defaultVariables,
    defaultOrderType,
    defaultFilterValue,
  } = props;
  const classes = useStyles();

  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState(defaultPagination);
  const [orderBy, setOrderBy] = useState(defaultOrderType || '');
  const [filterValue, setFilterValue] = useState(defaultFilterValue || '');

  const { data: tableRowData = {}, loading, refetch } = useQuery(fetchQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      orderBy,
      ...(defaultVariables || {}),
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
    },
  });

  const tableData = tableRowData[dataKey] || {};
  const { totalCount } = tableData;

  const handleItemDelete = useCallback(row => {
    onDelete(
      row.id,
      row,
      {
        query: fetchQuery,
        variables: {
          orderBy,
          limit: pagination.rowsPerPage,
          offset: pagination.rowsPerPage * pagination.page,
        },
      }
    );
  }, [pagination, onDelete, fetchQuery, orderBy]);

  const handleRowClick = useCallback(row => {
    setSelectedRow(row);
    onRowClick(row);
  }, [setSelectedRow, onRowClick]);

  const handleOrderChange = useCallback(orderBy => {
    setOrderBy(orderBy);

    return refetch({
      orderBy,
      limit: pagination.rowsPerPage,
      offset: pagination.rowsPerPage * pagination.page,
    });
  }, [pagination, setOrderBy, refetch]);

  const handleFetchMore = useCallback(stateData => {
    const newPaginationState = {
      ...pagination,
      ...stateData,
    };

    setPagination(newPaginationState);

    const variables = {
      limit: newPaginationState.rowsPerPage,
      offset: newPaginationState.rowsPerPage * newPaginationState.page,
    };

    if (orderBy) {
      variables.orderBy = orderBy;
    }

    return refetch(variables);
  }, [orderBy, refetch, pagination]);

  const handleFilterInputChange = useCallback(event => {
    const { value } = event.target;
    setFilterValue(value);
  }, [setFilterValue]);

  const showActions = !!onDelete || !!onEdit;
  const filteredData = useMemo(() => {
    return onRowFilter(tableData.nodes, filterValue)
  }, [onRowFilter, tableData, filterValue]);

  return (
    <div className={classes.root}>
      <Typography
        variant="h4"
        color="primary"
      >
        {title}
      </Typography>

      <div className="flex-row align-center justify-between">
        <div className="mrg-sides-10">
          <TextField
            margin="normal"
            value={filterValue}
            placeholder="Filter rows"
            onChange={handleFilterInputChange}
          />
        </div>
        <TableOrderSelect
          orderOptions={orderOptions}
          onChange={handleOrderChange}
          defaultOrderType={defaultOrderType}
        />
      </div>

      <Loading isLoading={loading}>
        <MUITable className={classes.table}>
          <TableHead>
            <TableRow>
              {
                tableColumns.map(col => (
                  <TableCell key={col.title}>
                    {col.title}
                  </TableCell>
                ))
              }

              {
                showActions && (
                  <TableCell>
                    Actions
                  </TableCell>
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .map((item, index) => {
                const rowClasses = classNames(classes.tableRow, {
                  [classes.selectedRow]: selectedRow && item.id && item.id === selectedRow.id,
                });

                return (
                  <TableRow
                    key={index}
                    className={rowClasses}
                    onClick={() => handleRowClick(item)}
                    onDoubleClick={() => onDoubleClick && onDoubleClick(item)}
                  >
                    {tableColumns.map(({ name, title, contentRenderer, getValue }) => {
                      const val = getValue ? getValue(item, item[name]) : get(item, name);
                      const content = contentRenderer ? contentRenderer(val, item) : val;

                      return (
                        <TableCell key={title}>
                          {content}
                        </TableCell>
                      );
                    })}

                    {showActions && (
                      <TableCell>
                        <div className="flex-row">
                          {
                            onEdit && (
                              <EditButton
                                onClick={() => onEdit(item)}
                              />
                            )
                          }
                          {
                            onDelete && (
                              <DeleteButton
                                onClick={() => handleItemDelete(item)}
                              />
                            )
                          }
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </MUITable>
      </Loading>

      <TablePagination
        rowCount={rowCount}
        rowLength={totalCount}
        pagination={pagination}
        fetchMore={handleFetchMore}
      />
    </div>
  );
}

Table.propTypes = {
  onRowFilter: PropTypes.func,
  defaultVariables: PropTypes.shape({}),
  /**
   * Title of table
   */
  title: PropTypes.string,
  /**
   * Event when the 'edit' button was clicked
   * or table row was 'double' clicked
   */
  onEdit: PropTypes.func,
  /**
   * Method for removing row.
   */
  onDelete: PropTypes.func,
  /**
   * Method which will be called when the row was clicked.
   */
  onRowClick: PropTypes.func,
  /**
   * Method which will be called when the row was double clicked.
   */
  onDoubleClick: PropTypes.func,
  /**
   * These are objects which tells table which properties should be shown in table.
   * The same properties also will be used for the filter.
   */
  tableColumns: tableColumnsType,
  /**
   * List of types of order.
   * Basically this is an order type of particular graphql Request
   */
  orderOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  /**
   * Fn which will be called on every order type select change
   */
  onOrderTypeChange: PropTypes.func,
  /**
   * Default order type value
   */
  defaultOrderType: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  fetchQuery: PropTypes.shape({}).isRequired,
};

Table.defaultProps = {
  title: '',
  onRowClick: noop,
  onRowFilter: d => d,
  defaultOrderType: 'CREATED_AT_DESC',
};

export default Table;
