import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import lodashGet from 'lodash.get';

import Loading from 'components/Loading';
import {
  Select,
} from 'components/Form';

import {
  FETCH_COMPOSITIONS,
} from '_graphql/actions/compositions';
import { generateSelectLabel } from 'containers/helpers';

function CompositionsSelect(props) {
  const { data = {}, loading } = useQuery(FETCH_COMPOSITIONS);

  const options = generateSelectLabel(lodashGet(data, 'compositions.nodes', []), 'title');

  return (
    <Loading isLoading={loading}>
      <Select
        label="Composition(s)"
        name="compositionId"
        options={options}
        {...props}
      />
    </Loading>
  );
}

CompositionsSelect.defaultProps = {
  isMulti: false,
};

CompositionsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CompositionsSelect;
