import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const getTitleText = (isEdit, title = '') => {
  if (!title) return '';

  const prefix = typeof isEdit !== 'boolean' ? '' : isEdit ? 'Edit' : 'Add';
  return `${prefix} ${title}`.toUpperCase();
};

const styles = theme => ({
  header: {
    padding: 10,
    marginTop: 10,
    backgroundColor: theme.palette.grey[200],
  },
});

function FormHeader(props) {
  const {
    title,
    isEdit,
    classes,
  } = props;

  return (
    <Typography
      variant="h5"
      color="primary"
      className={classes.header}
    >
      {getTitleText(isEdit, title)}
    </Typography>
  );
}

export default withStyles(styles)(FormHeader);
