import Genres from './';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/genres',
    title: 'Genres',
    component: Genres,
  },
];

export default routes;
