export const checkPagePath = path => {
  let isValid = true;
  if (!path || !path.trim()) {
    alert('Article\'s path is required');
    isValid = false;
  } else if (new RegExp('[A-Z]', 'g').test(path)) {
    alert('Article\'s path should contain only lower case letters.');
    isValid = false;
  } else if (
    // TODO: Minimize the regexp
    !new RegExp('^([a-z][-])*(\\S)*$').test(path) // check for whitespaces and formula
  ) {
    alert('Article\'s path should contain only hyphens without whitespaces.');
    isValid = false;
  }
  return isValid;
};
