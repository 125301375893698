import { gql } from 'apollo-boost';
import { musicianAll } from '_graphql/fragments';

export const FETCH_MUSICIANS = gql`
  query FetchMusicians($limit: Int, $offset: Int, $orderBy: [MusiciansOrderBy!]) {
    musicians(first: $limit, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...musicianAll
      }
      totalCount
    }
  }

  ${musicianAll}
`;

export const FETCH_SINGLE_MUSICIAN = gql`
  query FetchSingleMusician($id: Int!) {
    musician(id: $id) {
      ...musicianAll
    }
  }

  ${musicianAll}
`;

export const CREATE_MUSICIAN = gql`
  mutation CreateMusician(
    $type: String!,
    $path: String!
    $birthday: Date
    $deathday: Date
  ) {
    createMusician(
      input: {
        musician: {
          birthday: $birthday
          deathday: $deathday
          type: $type
          path: $path
          published: false
        }
      }) {
      musician {
        ...musicianAll
      }
    }
  }

  ${musicianAll}
`;

export const UPDATE_MUSICIAN = gql`
  mutation UpdateMusician(
    $id: Int!
    $type: String!,
    $path: String!
    $birthday: Date
    $deathday: Date
    $photoId: Int
    $published: Boolean
    $publishedAt: Datetime
  ) {
    updateMusician(
      input: {
        id: $id
        patch: {
          type: $type
          path: $path,
          photoId: $photoId
          deathday: $deathday
          birthday: $birthday
          published: $published
          publishedAt: $publishedAt
        }
      }) {
      musician {
        ...musicianAll
      }
    }
  }

  ${musicianAll}
`;

export const DELETE_MUSICIAN = gql`
  mutation DeleteMusician($id: Int!) {
    deleteMusician(input: { id: $id }) {
      musician {
        id
        nodeId
      }
    }
  }
`;


export const CREATE_MUSICIAN_COMPOSITION = gql`
  mutation CreateMusicianComposition($compositionId: Int!, $musicianId: Int!) {
    createMusicianComposition(input: {
      musicianComposition: {
        compositionId: $compositionId
        musicianId: $musicianId
      }
    }) {
      musician {
        ...musicianAll
      }
    }
  }
  ${musicianAll}
`;

export const DELETE_MUSICIAN_COMPOSITION = gql`
  mutation DeleteMusicianComposition($compositionId: Int!, $musicianId: Int!) {
    deleteMusicianComposition(input: {
      compositionId: $compositionId
      musicianId: $musicianId
    }) {
        musician {
          ...musicianAll
        }
      composition {
        nodeId
      }
    }
  }
  ${musicianAll}
`;

export const CREATE_MUSICIAN_PROFESSION = gql`
  mutation CreateMusicianProfession($professionId: Int!, $musicianId: Int!) {
    createMusicianProfession(input: {
      musicianProfession: {
        professionId: $professionId
        musicianId: $musicianId
      }
    }) {
      musician {
        ...musicianAll
      }
    }
  }
  ${musicianAll}
`;

export const DELETE_MUSICIAN_PROFESSION = gql`
  mutation DeleteMusicianProfession($professionId: Int!, $musicianId: Int!) {
    deleteMusicianProfession(input: {
      professionId: $professionId
      musicianId: $musicianId
    }) {
      musician {
        ...musicianAll
      }
      profession {
        id
        nodeId
      }
    }
  }
  ${musicianAll}
`;
