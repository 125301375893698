import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Form, {
  FormSection,
} from 'components/Form';
import DropZone from 'components/DropZone';
import PDFViewer from 'components/PDFViewer';
import withUser from 'hoc/withUser';

import { renderFormField } from 'helpers/components/renderFormField';

const styles = {
  pdf: {
    minHeight: 800,
  },
};

const defaultOptions = {
  rotation: 0,
  opacity: 0.6,
  pages: '5-',
  scaleFactor: 0.5,
  scalePosition: 'abs',
  watermarkPages: '2-',
};

const fields = [
  {
    name: 'opacity',
    required: true,
    label: 'Opacity',
  },
  {
    required: true,
    name: 'pages',
    label: 'Pages',
  },
  {
    required: true,
    name: 'scaleFactor',
    label: 'Scale Factor',
  },
  {
    required: true,
    name: 'scalePosition',
    label: 'Scale Position',
  },
  {
    required: true,
    name: 'rotation',
    label: 'Scale Rotation',
  },
];

const stampFields = [
  {
    name: 'stampRight',
    required: true,
    label: 'The stamp with blue BG',
  },
  {
    name: 'stampCenter',
    required: true,
    label: 'The main pages stamp',
  },
];

function ScoreFiles(props) {
  const {
    score,
    classes,
    isAdmin,
    updateScore,
    onStampUpdate,
  } = props;
  const [previewData, setPreviewData] = useState({});
  const [previewDialogOptions, setPreviewDialogOptions] = useState({ open: false });

  useEffect(() => {
    if (score.preview) {
      setPreviewData({ ...score.preview });
    } else {
      setPreviewData({ options: defaultOptions });
    }
  }, [score.preview]);


  const handleScoreFileUpload = useCallback(file => {
    return updateScore({
      url: file,
    });
  }, [updateScore]);

  const openPreviewDialog = useCallback(url => {
    setPreviewDialogOptions(options => ({ open: !options.open, previewUrl: url }));
  }, [setPreviewDialogOptions]);

  const closePreviewDialog = useCallback(() => {
    setPreviewDialogOptions(options => ({ open: !options.open, previewUrl: null }));
  }, [setPreviewDialogOptions]);

  const handlePreviewPageChanges = useCallback(previewOptions => {
    return updateScore({
      previewOptions: {
        ...previewOptions,
        // we are using the text field to pass pages separated by comma
        // e.g. 1,3,4-6 etc
        // but the server accepts an array of pages
        // so we transform pages from string to an array and vise versa
        pages: typeof previewOptions.pages === 'string'
          ? previewOptions.pages.split(',')
          : previewOptions.pages,
        watermarkPages: typeof previewOptions.watermarkPages === 'string'
          ? previewOptions.watermarkPages.split(',')
          : previewOptions.watermarkPages
      }
    });
  }, [updateScore]);

  return (
    <>
      <FormSection title="Add a score file">
        <DropZone
          accept=".pdf"
          onFileUpload={handleScoreFileUpload}
        />

        <div className="flex-row wrap grow justify-center align-center">
          {previewData.url && (
            <div className="pad-15">
              <Button onClick={() => openPreviewDialog(previewData.url)}>
                Click to see the score's preview pages
              </Button>
            </div>
          )}

          {score.url && (
            <div className="pad-15">
              <Button onClick={() => openPreviewDialog(score.url)}>
                Click to view the score
              </Button>
            </div>
          )}
        </div>
      </FormSection>

      {score.url && (
        <>
          <Form
            isEdit={score.id}
            title="Select Preview Options"
            defaultData={previewData.options}
            onSubmit={handlePreviewPageChanges}
            renderContent={(onFormChange, formData) => {
              return (
                <FormSection>
                  <Grid
                    container
                    spacing={1}
                  >
                    {fields.map(inp => (
                      <Grid key={inp.name} item xs={2}>
                        {
                          renderFormField({
                            inp: {
                              ...inp,
                              props: {
                                ...(inp.props || {}),
                                disabled: !isAdmin && inp.name !== 'pages',
                              }
                            },
                            formData,
                            onFormChange,
                          })
                        }
                      </Grid>
                    ))}
                  </Grid>
                </FormSection>
              );
            }}
          />

          <Form
            isEdit={score.id}
            title="Stamp pages"
            onSubmit={onStampUpdate}
            defaultData={score.stamp}
            renderContent={(onFormChange, formData) => {
              return (
                <FormSection>
                  <Grid
                    container
                    spacing={1}
                  >
                    {stampFields.map(inp => (
                      <Grid key={inp.name} item xs={2}>
                        {
                          renderFormField({
                            inp,
                            formData,
                            onFormChange,
                          })
                        }
                      </Grid>
                    ))}
                  </Grid>
                </FormSection>
              );
            }}
          />
        </>
      )}

      {previewDialogOptions.open && (
        <Dialog
          open
          fullWidth
          maxWidth="xl"
          scroll="paper"
          onClose={closePreviewDialog}
        >
          {
            previewDialogOptions.previewUrl && (
              <DialogContent
                dividers
                className={classes.content}
              >
                <PDFViewer
                  className={classes.pdf}
                  pdfUrl={previewDialogOptions.previewUrl}
                />
              </DialogContent>
            )
          }
        </Dialog>
      )}

    </>
  );
}

ScoreFiles.defaultProps = {};

ScoreFiles.propTypes = {
  score: PropTypes.shape({
    url: PropTypes.string,
    preview: PropTypes.shape({
      pages: PropTypes.string,
      rotation: PropTypes.number,
      opacity: PropTypes.number,
      scaleFactor: PropTypes.number,
      scalePosition: PropTypes.string,
    }),
  }),
};

export default withStyles(styles)(withUser(ScoreFiles));
