import lodashCloneDeep from 'lodash.clonedeep';

export const updateQueryWithNodes = key => (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return prev;
  }
  
  const clonedData = lodashCloneDeep(fetchMoreResult);
  clonedData[key].nodes = [...prev[key].nodes, ...fetchMoreResult[key].nodes];

  return clonedData;
};
