export default theme => ({
  nested: {
    marginLeft: theme.spacing(),
  },
  title: {
    display: 'flex',
  },
  titleText: {
    flexGrow: 1,
  },
  selectedItem: {
    backgroundColor: theme.palette.grey[300],
  },
  selectedItemText: {
    color: theme.palette.primary.main,
  },
});
