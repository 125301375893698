import { gql } from 'apollo-boost';

export const compositionLocaleAll = gql`
  fragment compositionLocaleAll on CompositionLocale {
    lang
    title
    nodeId
    description
  }
`;


export const compositionAll = gql`
  fragment compositionAll on Composition {
    id
    path
    nodeId
    published
    publishedAt
    locales: compositionLocalesBySourceIdList {
      ...compositionLocaleAll
    }
  }
  
  ${compositionLocaleAll}
`;
