import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import lodashGet from 'lodash.get';

import Loading from 'components/Loading';
import {
  Select,
} from 'components/Form';

import {
  FETCH_PROFESSIONS,
} from '_graphql/actions/professions';
import { generateSelectLabel } from 'containers/helpers';

function ProfessionsSelect(props) {
  const { data = {}, loading } = useQuery(FETCH_PROFESSIONS);

  const options = generateSelectLabel(lodashGet(data, 'professions.nodes', []));

  return (
    <Loading isLoading={loading}>
      <Select
        isMulti
        name="professions"
        label="Professions"
        options={options}
        {...props}
      />
    </Loading>
  );
}

ProfessionsSelect.defaultProps = {};

ProfessionsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ProfessionsSelect;
