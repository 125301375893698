export default {
  tabs: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  sticky: {
    border: '1px solid rgba(0, 0, 0, .3)',
    position: 'sticky',
    zIndex: 1,
    top: 0,
  },
  tab: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flagImage: {
    marginRight: 10,
    width: 20,
  },
};
