import { gql } from 'apollo-boost';

export const promoCodeMain = gql`
  fragment promoCodeMain on PromoCode {
    code
    nodeId
    status
    percent
    createdAt
    updatedAt
    expiresAt
  }
`;

export const promoCodeAll = gql`
  fragment promoCodeAll on PromoCode {
    ...promoCodeMain
  }

  ${promoCodeMain}
`;
