import {
  sanitizeString,
} from 'helpers/text/sanitizeString';

/**
 * @param {string} mainTerm - The main string where search will be done
 * @param {string} searchTerm - The search term
 * @returns {boolean}
 */
export const searchString = (mainTerm, searchTerm) => sanitizeString(mainTerm).includes(sanitizeString(searchTerm));
