import React, { useState, useCallback } from 'react';
import { Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { renderRoutes } from 'react-router-config';
import { withStyles } from '@material-ui/core/styles';

import Header from 'sections/Header';
import Sidebar from 'sections/Sidebar';

import routes from 'routes';
import withUser from 'hoc/withUser';

import styles from './styles';

function MainApp(props) {
  const [isSidebarOpen, setSidebarOpen] = useState(!isMobile);
  
  const { user, isAdmin, classes, isEditor } = props;
  
  const hasPermissions = isAdmin || isEditor;
  
  const toggleSidebar = useCallback(() => {
    setSidebarOpen((isSidebarOpen) => !isSidebarOpen);
  }, [setSidebarOpen]);
  
  if (!hasPermissions) {
    return (
      <div className="pad-20 flex-row grow align-center justify-center">
        <h2>
          Permissions to Admin Panel of ANM is denied for user {user.firstName}{' '}
          {user.lastName}
        </h2>
      </div>
    );
  }
  
  return (
    <div className={classes.root}>
      <Header toggleSidebar={toggleSidebar}/>
      <Sidebar isDrawerOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
      <main className={classes.content}>
        <Switch>
          {renderRoutes(routes)}
        </Switch>
      </main>
    </div>
  );
}

export default withStyles(styles)(withUser(MainApp));
