import { gql } from 'apollo-boost';
import { musicianLocalesAll } from '_graphql/fragments';

export const FETCH_MUSICIAN_LOCALES = gql`
  query FetchMusicianLocales($id: Int!) {
    musician(id: $id) {
      locales: musicianLocalesBySourceIdList {
        ...musicianLocalesAll
      }
    }
  }

  ${musicianLocalesAll}
`;

export const CREATE_MUSICIAN_LOCALE = gql`
  mutation CreateMusicianLocale(
    $musicianId: Int!
    $lang: String!
    $firstName: String!
    $lastName: String!
    $biography: String!
  ) {
    createMusicianLocale(input: {
      musicianLocale: {
        sourceId: $musicianId
        firstName: $firstName
        biography: $biography
        lastName: $lastName
        lang: $lang
      }
    }
    ) {
      musicianLocale {
        ...musicianLocalesAll
      }
    }
  }

  ${musicianLocalesAll}
`;

export const UPDATE_MUSICIAN_LOCALE = gql`
  mutation UpdateMusicianLocale(
    $musicianId: Int!
    $lang: String!
    $firstName: String!
    $lastName: String!
    $biography: String!
  ) {
    updateMusicianLocale(input: {
      lang: $lang
      sourceId: $musicianId
      patch: {
        firstName: $firstName
        biography: $biography
        lastName: $lastName
        sourceId: $musicianId
        lang: $lang
      }
    }
    ) {
      musicianLocale {
        ...musicianLocalesAll
      }
    }
  }

  ${musicianLocalesAll}
`;
