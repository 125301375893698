import lodashMemoize from 'lodash.memoize';

import contactMessagesRoutes from 'pages/ContactMessages/routes';
import compositionsRoutes from 'pages/Compositions/routes';
import pageSectionsRoutes from 'pages/PageSections/routes';
import professionsRoutes from 'pages/Professions/routes';
import instrumentsRoutes from 'pages/Instruments/routes';
import promoCodesRoutes from 'pages/PromoCodes/routes';
import termsRoutes from 'pages/TermsAndPrivacy/routes';
import musiciansRoutes from 'pages/Musicians/routes';
import articlesRoutes from 'pages/Articles/routes';
import genresRoutes from 'pages/Genres/routes';
import scoreRoutes from 'pages/Scores/routes';
import usersRoutes from 'pages/Users/routes';
import tagsRoutes from 'pages/Tags/routes';
import authRoutes from 'pages/Auth/routes';

import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';

import NotFound from 'pages/NotFound';
import Home from 'pages/Home';

const getMainRoutes = routes => routes.filter(route => route.isMain);

const defaultRoutes = [
  {
    exact: true,
    path: '/',
    component: Home,
  },
  ...usersRoutes,
  ...articlesRoutes,
  ...musiciansRoutes,
  ...scoreRoutes,
  ...compositionsRoutes,
  ...contactMessagesRoutes,
  ...tagsRoutes,
  ...genresRoutes,
  ...professionsRoutes,
  ...instrumentsRoutes,
  ...promoCodesRoutes,
  ...termsRoutes,
  ...authRoutes,
  ...pageSectionsRoutes,
  {
    component: NotFound,
  }
];

export const sidebarRoutes = lodashMemoize(() => getMainRoutes([
  ...usersRoutes,
  ...articlesRoutes,
  ...musiciansRoutes,
  ...scoreRoutes,
  ...compositionsRoutes,
  {
    title: 'Settings',
    isMain: true,
    icon: SettingsIcon,
    routes: [
      ...getMainRoutes(contactMessagesRoutes),
      ...getMainRoutes(promoCodesRoutes),
      ...getMainRoutes(pageSectionsRoutes),
      ...getMainRoutes(termsRoutes),
    ],
  },
  {
    title: 'Additional Data',
    isMain: true,
    icon: BuildIcon,
    routes: [
      ...getMainRoutes(tagsRoutes),
      ...getMainRoutes(genresRoutes),
      ...getMainRoutes(professionsRoutes),
      ...getMainRoutes(instrumentsRoutes),
    ],
  },
]))();

export const getRoutes = () => defaultRoutes;
export default defaultRoutes;

