import React, { useState, useEffect, useCallback } from "react";
import { Select } from "components/Form";
import PropTypes from "prop-types";

import "./styles.sass";

function TableOrderSelect(props) {
  const {
    onChange,
    orderOptions,
    defaultOrderType,
  } = props;
  const [selectedOrderType, setSelectedOrderType] = useState(null);

  useEffect(() => {
    if (!selectedOrderType && defaultOrderType) {
      setSelectedOrderType(defaultOrderType);
    }
  }, [setSelectedOrderType, selectedOrderType, defaultOrderType]);

  const handleOnChange = useCallback((option) => {
    setSelectedOrderType(option.orderBy);
    onChange(option.orderBy);
  }, [onChange, setSelectedOrderType]);

  if (!orderOptions || !orderOptions.length) {
    return null;
  }

  return (
    <Select
      name="orderBy"
      label="Order By"
      fullWidth={false}
      options={orderOptions}
      value={selectedOrderType}
      onChange={handleOnChange}
      className="table-order-select"
    />
  );
}

TableOrderSelect.defaultProps = {};

TableOrderSelect.propTypes = {
  orderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  defaultOrderType: PropTypes.string,
  onChange: PropTypes.func,
};

export default TableOrderSelect;
