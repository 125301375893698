import React, { useState, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import lodashCloneDeep from 'lodash.clonedeep';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { Formik } from 'formik';

import {
  TextField,
  FormSection,
} from 'components/Form';
import Image from 'components/Image';
import DropZone from 'components/DropZone';
import PathInput from 'components/PathInput';
import GoBackPageButtonRow from 'components/GoBackPageButtonRow';

import InstrumentsSelect from 'containers/InstrumentsSelect';
import CompositionsSelect from 'containers/CompositionsSelect';

import {
  checkPagePath,
  getSelectInputData,
} from 'helpers';

import withUser from 'hoc/withUser';

import PublishRow from 'sections/PublishRow';
import ContentSection from 'sections/ContentSection';

import ScoreLocales from './ScoreLocales';
import ScoreFiles from './ScoreFiles';

import {
  CREATE_SCORE,
  UPDATE_SCORE,
  FETCH_SINGLE_SCORE,
} from '_graphql/actions';

const defaultPrices = JSON.stringify([
  {
    currency: 'AMD',
    amount: 0,
  },
  {
    currency: 'USD',
    amount: 0,
  },
]);

const refetchQueries = ['FetchScores', 'FetchSingleScore'];

function ScoreDetails(props) {
  const {
    isAdmin,
    match: { params },
  } = props;
  
  const [scorePrices, setScorePrices] = useState(defaultPrices);
  const [score, setScoreData] = useState({});

  const handleScoreStateUpdate = useCallback(newScore => {
    newScore.instruments = getSelectInputData(newScore.instruments);
    if (newScore.prices) {
      setScorePrices(newScore.prices);
    }
    setScoreData(newScore);
  }, [setScorePrices, setScoreData]);

  const { loading } = useQuery(FETCH_SINGLE_SCORE, {
    variables: {
      id: Number(params.scoreId),
    },
    skip: !params.scoreId,
    onCompleted: (data) => {
      if (data && data.score) {
        handleScoreStateUpdate(data.score);
      }
    },
  });

  const [createScore, { loading: createScoreLoading }] = useMutation(CREATE_SCORE, {
    onCompleted: ({ createScore: { score } }) => {
      const newScore = lodashCloneDeep(score);
      newScore.instruments = getSelectInputData(newScore.instruments);
      props.history.replace(`/score/details/${newScore.id}`);
    },
  });

  const [updateScore, { loading: updateScoreLoading }] = useMutation(UPDATE_SCORE, {
    onCompleted: ({ updateScore: { score: newScore} }) => {
      handleScoreStateUpdate(newScore);
    },
  });

  const isNewScore = !!(score && !score.id);

  const handleFormSubmit = useCallback(async data => {
    if (checkPagePath(data.path)) {
      const action = isNewScore
        ? createScore
        : updateScore;

      const {
        id,
        path,
        instruments,
      } = data;

      return action({
        variables: {
          id,
          path,
          instruments,
          prices: scorePrices,
        },
        refetchQueries,
      });
    }
  }, [scorePrices, createScore, updateScore, isNewScore]);

  const handleScorePublish = useCallback(publishData => {
    return updateScore({
      variables: {
        id: score.id,
        path: score.path,
        prices: score.prices,
        ...publishData
      },
    });
  }, [updateScore, score]);

  const handleScoreFileUpdates = useCallback(({ previewOptions, ...variables }) => {
    const vars = {
      variables: {
        id: score.id,
        path: score.path,
        prices: score.prices,
        ...variables,
      },
      refetchQueries,
    };

    if (previewOptions) {
      const { __typename, ...options } = previewOptions;
      vars.variables.previewOptions = options;
    }

    return updateScore(vars);
  }, [updateScore, score]);

  const handleScoreUpdate = useCallback(data => {
    return updateScore({
      variables: {
        id: score.id,
        path: score.path,
        ...data,
      },
      refetchQueries,
    });
  }, [updateScore, score]);

  const isLoading = loading
    || updateScoreLoading
    || createScoreLoading;

  const prices = JSON.parse(scorePrices);

  return (
    <ContentSection isLoading={isLoading}>
      <GoBackPageButtonRow
        goTo="/scores"
      />

      {!!score ? (
        <>
          <Grid
            container
            spacing={2}
            direction="row"
            key={String(isLoading)}
          >
            <Grid item xs={4} className="mrg-top-15">
              {score.poster && (
                <Image
                  src={score.poster}
                  onDelete={() => handleScoreUpdate({ poster: null })}
                />
              )}
            </Grid>

            <Grid item xs={4}>
              <DropZone
                title="Click to upload score's poster image"
                onFileUpload={poster => handleScoreUpdate({ poster })}
              />
            </Grid>
          </Grid>

          <div className="mrg-top-15">
            <Formik
              initialValues={score}
              key={score && score.id}
              onSubmit={handleFormSubmit}
            >
              {({
                  values,
                  handleSubmit,
                  setFieldValue,
                }) => (
                <form onSubmit={handleSubmit}>
                  <FormSection>
                    <Grid
                      container
                      spacing={3}
                    >
                      {
                        (prices.map(priceField => (
                          <Grid
                            item
                            sm={6}
                            xs={12}
                            key={priceField.currency}
                          >
                            <TextField
                              fullWidth
                              type="number"
                              name={priceField.currency}
                              onChange={value => {
                                const values = lodashCloneDeep(prices);
                                const valIndex = (values || []).findIndex(v => v.currency === priceField.currency);
                                values[valIndex].amount = parseFloat(value[priceField.currency]);
                                setScorePrices(JSON.stringify(values));
                              }}
                              value={priceField.amount}
                              label={`Score Price ${priceField.currency}`}
                            />
                          </Grid>
                        )))
                      }
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        sm={6}
                        xs={12}
                      >
                        <PathInput
                          value={values.path || ''}
                          disabled={!isAdmin && !isNewScore}
                          onChange={value => setFieldValue('path', value.path)}
                        />
                      </Grid>

                      <Grid
                        item
                        sm={6}
                        xs={12}
                      >
                        <InstrumentsSelect
                          required
                          value={values.instruments}
                          onChange={value => setFieldValue('instruments', value.instruments)}
                        />
                      </Grid>
                    </Grid>
                  </FormSection>

                  <div className="flex-row justify-end">
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>

          {score.id && (
            <>
              <CompositionsSelect
                value={score.compositionId}
                onChange={option => updateScore({
                  variables: {
                    id: score.id,
                    path: score.path,
                    compositionId: option.compositionId,
                  }
                })}
              />

              <PublishRow
                published={score.published}
                onUpdate={handleScorePublish}
                publishedAt={score.publishedAt}
              />

              <FormSection title="Score info">
                <ScoreLocales
                  scoreId={score.id}
                />
              </FormSection>

              <ScoreFiles
                score={score}
                onStampUpdate={handleScoreUpdate}
                updateScore={handleScoreFileUpdates}
              />
            </>
          )}
        </>
      ) : 'Loading...'
      }
    </ContentSection>
  );
}

export default withUser(ScoreDetails);
