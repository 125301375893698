import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialPaper from '@material-ui/core/Paper';

const styles = {
  paper: {
    padding: 10,
  },
};

function Paper({ className, classes, ...props }) {
  return (
    <MaterialPaper className={`${classes.paper} ${className}`} {...props} />
  );
}

Paper.defaultProps = {
  className: '',
};

export default withStyles(styles)(Paper);
