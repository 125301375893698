import { gql } from 'apollo-boost';
import { imageAll } from '_graphql/fragments/images';

export const musicianLocalesAll = gql`
  fragment musicianLocalesAll on MusicianLocale {
    lang
    nodeId
    lastName
    firstName
    biography
    updatedAt
  }
`;

export const musicianMain = gql`
  fragment musicianMain on Musician {
    id
    path
    type
    photo {
      ...imageAll
    }
    nodeId
    photoId
    birthday
    deathday
    createdAt
    updatedAt
    published
    publishedAt
    compositions: compositionsList {
      id
    }
    professions: musicianProfessionsList {
      profession {
        id
        name
      }
    }
  }
  
  ${imageAll}
`;

export const musicianAll = gql`
  fragment musicianAll on Musician {
    ...musicianMain
    
    photo {
      ...imageAll
    }
   
    locales: musicianLocalesBySourceIdList {
      ...musicianLocalesAll
    }
  }

  ${imageAll}
  ${musicianMain}
  ${musicianLocalesAll}
`;
