export default [
  {
    role: 'ADMIN',
    title: 'Admin',
  },
  {
    role: 'EDITOR',
    title: 'Editor',
  },
  {
    role: 'MEMBER',
    title: 'Member',
  },
];
