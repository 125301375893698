import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import lodashDebounce from 'lodash.debounce';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { updateQueryWithNodes } from '_graphql/helpers';

function SectionWithFetchMore(props) {
  const {
    items,
    useWindow,
    direction,
    fetchMore,
    isLoading,
    renderItem,
    hasNextPage,
    queryDataKey,
    wrapperClassName,
    WrapperComponent,
  } = props;
  
  const handleFetchMore = useCallback(lodashDebounce(() => {
    fetchMore({
      variables: {
        offset: items.length,
      },
      updateQuery: updateQueryWithNodes(queryDataKey),
    });
  }, 100), [items, fetchMore, queryDataKey]);
  
  const isRow = direction === 'row';
  
  return (
    <>
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        useWindow={useWindow}
        hasMore={hasNextPage}
        loadMore={handleFetchMore}
      >
        <WrapperComponent
          className={classNames(
            'mrg-top-15 justify-center',
            {
              'wrap': isRow,
              'flex-row': isRow,
              'align-end': isRow,
              'flex-column': direction === 'column',
            },
            wrapperClassName,
          )}
        >
          {items.length
            ? items.map(renderItem)
            : !isLoading && (
            <Typography
              variant="h6"
              color="textSecondary"
            >
              No Data to show
            </Typography>
          )}
        </WrapperComponent>
        {isLoading && (
          <div className="mrg-top-15 flex-row align-center grow justify-center">
            <Typography
              color="textSecondary"
            >
              Loading...
            </Typography>
            
            <CircularProgress
              size={18}
              className="mrg-sides-10"
            />
          </div>
        )}
      </InfiniteScroll>
      
      <div className="flex-row align-center justify-center mrg-top-15 pad-bottom-15">
        <Button onClick={handleFetchMore}>
          Fetch More
        </Button>
      </div>
    </>
  );
}

SectionWithFetchMore.defaultProps = {
  useWindow: true,
  isLoading: false,
  direction: 'row',
  hasNextPage: false,
  itemCountPerRequest: 1,
  WrapperComponent: props => <div {...props} />,
};

SectionWithFetchMore.propTypes = {
  isLoading: PropTypes.bool,
  fetchMore: PropTypes.func,
  wrapperClassName: PropTypes.string,
  itemCountPerRequest: PropTypes.number,
  renderItem: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  WrapperComponent: PropTypes.elementType,
  queryDataKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  direction: PropTypes.oneOf(['row', 'column']),
};

export default SectionWithFetchMore;
