import React, { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { searchInTableData } from 'helpers/array/searchInTableData';

import Table from 'components/Table';

import BaseSection from 'sections/BaseSection';

import {
  FETCH_OPEN_MESSAGES,
  DELETE_OPEN_MESSAGE,
} from '_graphql/actions';

import withUser from 'hoc/withUser';

const tableColumns = [
  {
    title: 'Send Date',
    getValue: item => new Date(item.createdAt).toISOString(),
  },
  {
    title: 'Name',
    name: 'name',
    getValue: item => item.name,
  },
  {
    title: 'Email',
    name: 'email',
    getValue: item => item.email,
  },
  {
    title: 'Message',
    name: 'message',
    getValue: item => item.message,
  },
  {
    title: 'File',
    getValue: item => {
      return item.attachedFile
        ? <a href={item.attachedFile} download target="_blank" rel="noopener noreferrer">Download / Open</a>
        : null;
    },
  },
];

const defaultVariables = {
  orderBy: 'CREATED_AT_DESC',
};

function ContactMessages(props) {
  const [deleteContactMessage, { loading: isDeleteLoading }] = useMutation(DELETE_OPEN_MESSAGE);
  const {
    isAdmin,
  } = props;

  const handleContactMessageDelete = useCallback(async messageId => {
    await deleteContactMessage({
      variables: {
        id: messageId,
      },
      refetchQueries: ['FetchContactMessages'],
    });
  }, [deleteContactMessage]);

  return (
    <BaseSection
      title="User Messages"
      isLoading={isDeleteLoading}
    >
      <Table
        showActions={false}
        dataKey="openMessages"
        tableColumns={tableColumns}
        fetchQuery={FETCH_OPEN_MESSAGES}
        defaultVariables={defaultVariables}
        onDelete={isAdmin ? handleContactMessageDelete : undefined}
        onRowFilter={(data, filterValue) => searchInTableData(data, tableColumns, filterValue)}
      />
    </BaseSection>
  );
}

ContactMessages.defaultProps = {};

ContactMessages.propTypes = {};

export default withUser(ContactMessages);
