import { gql } from 'apollo-boost';
import { userAll } from '_graphql/fragments';

export const AUTHENTICATE_USER = gql`
  query AuthenticateUser {
    currentUser {
      ...userAll
    }
  }
  ${userAll}
`;

export const SIGN_IN = gql`
  mutation Login ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      message
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser ($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    createUser(input: {user: {
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    }}) {
      user {
        ...userAll
      }
    }
  }

  ${userAll}
`;

export const LOGOUT = gql`
  mutation {
    logout
  }
`;

export const FETCH_USERS = gql`
  query FetchUSers($limit: Int, $offset: Int) {
    users(orderBy: CREATED_AT_DESC, first: $limit, offset: $offset) {
      nodes {
        ...userAll
      }
      totalCount
    }
  }
  ${userAll}
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    deleteUser(input: {id: $id}) {
      user {
        nodeId
      }
    }
  }
`;

export const CHANGE_USER_ROLE = gql`
  mutation ChangeUserRole($id: Int!, $role: UserRole!) {
    changeUserRole(input: { role: $role, userId: $id }) {
        user {
          id
          nodeId
          role
        }
    }
  }
`;
