import { gql } from 'apollo-boost';
import { scoreLocalesAll } from '_graphql/fragments';

export const FETCH_SCORE_LOCALES = gql`
  query FetchScoreLocales($id: Int!) {
    score(id: $id) {
      locales: scoreLocalesBySourceIdList {
        ...scoreLocalesAll
      }
    }
  }

  ${scoreLocalesAll}
`;

export const CREATE_SCORE_LOCALE = gql`
  mutation CreateScoreLocale(
    $scoreId: Int!
    $lang: String!
    $title: String!
    $description: String!
  ) {
    createScoreLocale(input: {
      scoreLocale: {
        sourceId: $scoreId
        description: $description
        title: $title
        lang: $lang
      }
    }
    ) {
      scoreLocale {
        ...scoreLocalesAll
      }
    }
  }

  ${scoreLocalesAll}
`;

export const UPDATE_SCORE_LOCALE = gql`
  mutation UpdateScoreLocale(
    $scoreId: Int!
    $lang: String!
    $title: String!
    $description: String!
  ) {
    updateScoreLocale(input: {
      lang: $lang
      sourceId: $scoreId
      patch: {
        description: $description
        sourceId: $scoreId
        title: $title
        lang: $lang
      }
    }
    ) {
      scoreLocale {
        ...scoreLocalesAll
      }
    }
  }

  ${scoreLocalesAll}
`;
