import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';

import styles from './styles';

function SidebarItem(props) {
  const {
    title,
    onClick,
    classes,
    ItemIcon,
    hasChild,
    isSelected,
    isNestedChild,
  } = props;

  const itemClasses = classNames({
    [classes.selectedItem]: isSelected,
    [classes.nested]: isNestedChild,
  });

  const itemTextClasses = classNames({
    [classes.selectedItemText]: isSelected,
  });

  return (
    <ListItem
      button
      onClick={onClick}
      className={itemClasses}
    >
      <ListItemIcon>
        <ItemIcon
          color={isSelected ? 'primary' : 'inherit'}
        />
      </ListItemIcon>
      <ListItemText
        className={itemTextClasses}
        primary={(
          <div className={classes.title}>
            <span className={classes.titleText}>
              {title}
            </span>
            {hasChild && <ExpandMoreIcon/>}
          </div>
        )}/>
    </ListItem>
  );
}

SidebarItem.defaultProps = {
  isSelected: false,
};

SidebarItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.shape({}),
  hasChild: PropTypes.bool,
  isSelected: PropTypes.bool,
  isNestedChild: PropTypes.bool,
};

export default withStyles(styles)(SidebarItem);
