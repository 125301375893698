import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';

import Form, {
  Select,
  FormSection,
} from 'components/Form';
import GoBackPageButtonRow from 'components/GoBackPageButtonRow';

import { renderFormField } from 'helpers';

import ContentSection from 'sections/ContentSection';

import withUser from 'hoc/withUser';

import {
  CREATE_PROMO_CODE,
  UPDATE_PROMO_CODE,
  FETCH_SINGLE_PROMO_CODE,
} from '_graphql/actions';

const promoCodeStatusTypes = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Used',
    value: 'USED',
  },
  {
    label: 'Canceled',
    value: 'CANCELED',
  },
];

const fields = [
  {
    required: true,
    name: 'status',
    label: 'Status',
    component: props => {
      return (
        <Select
          {...props}
          options={promoCodeStatusTypes}
        />
      );
    }
  },
  {
    required: true,
    name: 'percent',
    label: 'Percent %',
    props: {
      type: 'number',
      InputProps: {
        inputProps: {
          min: 0,
          max: 100,
        },
      }
    },
  },
  {
    required: true,
    name: 'expiresAt',
    label: 'Expire date',
    props: {
      type: 'date',
    },
  },
];

const defaultPromoCode = {
  status: promoCodeStatusTypes[0].value,
};

function PromoCodeDetails(props) {
  const {
    history,
    match: { params },
  } = props;
  const [promoCode, setPromoCodeData] = useState(defaultPromoCode);
  const [fetchSinglePromoCode, { loading }] = useLazyQuery(FETCH_SINGLE_PROMO_CODE, {
    onCompleted: ({ promoCode }) => {
      setPromoCodeData(promoCode);
    },
  });

  const [createPromoCode, { loading: createPromoCodeLoading }] = useMutation(CREATE_PROMO_CODE, {
    onCompleted: ({ createPromoCode: { promoCode: newPromoCode } }) => {
      setPromoCodeData(newPromoCode);
      history.replace(`/promo-code/details/${newPromoCode.code}`);
    },
  });

  const [updatePromoCode, { loading: updatePromoCodeLoading }] = useMutation(UPDATE_PROMO_CODE);

  useEffect(() => {
    const { promoCodeId } = params;
    if (promoCodeId) {
      fetchSinglePromoCode({
        variables: {
          code: promoCodeId,
        },
      });
    }
  }, [fetchSinglePromoCode, params]);

  const isNewPromoCode = !promoCode.code;

  const handleFormSubmit = useCallback(async data => {
    const action = isNewPromoCode
      ? createPromoCode
      : updatePromoCode;

    return action({
      variables: data,
      refetchQueries: ['FetchPromoCodes'],
    });
  }, [createPromoCode, updatePromoCode, isNewPromoCode]);

  const isLoading = loading
    || updatePromoCodeLoading
    || createPromoCodeLoading;

  return (
    <ContentSection isLoading={isLoading}>
      <GoBackPageButtonRow
        goTo="/promo-codes"
      />

      <Form
        title="Detail info"
        key={promoCode.code}
        defaultData={promoCode}
        isEdit={!isNewPromoCode}
        onSubmit={handleFormSubmit}
        renderContent={(handleFormChange, formData) => {
          return (
            <FormSection>
              <Grid
                container
                spacing={1}
                direction="row"
                alignItems="center"
              >
                {fields.map(inp => (
                  <Grid
                    item
                    xs={3}
                    key={inp.name}
                  >
                    {renderFormField({
                      inp,
                      formData,
                      onFormChange: handleFormChange,
                    })}
                  </Grid>
                ))}
              </Grid>
            </FormSection>
          );
        }}
      />
    </ContentSection>
  );
}

export default withUser(PromoCodeDetails);
