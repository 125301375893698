import { gql } from 'apollo-boost';
import { instrumentAll } from '_graphql/fragments/instruments';

export const scoreLocalesAll = gql`
  fragment scoreLocalesAll on ScoreLocale {
    lang
    title
    nodeId
    description
  }
`;

export const scorePreviewAll = gql`
  fragment scorePreviewAll on Preview {
    url
    options {
      pages
      scaleFactor
      scalePosition
      rotation
      opacity
      watermarkPages
    }
  }
`;

export const scoreMain = gql`
  fragment scoreMain on Score {
    id
    url
    path
    prices
    nodeId
    poster
    createdAt
    updatedAt
    published
    publishedAt
    compositionId
    composition {
      musiciansList {
        id
        firstName
        lastName
      }
    }
    preview {
      ...scorePreviewAll
    }
    instruments: instrumentsList {
      ...instrumentAll
    }
  }
  ${scorePreviewAll}
  ${instrumentAll}
`;

export const scoreAll = gql`
  fragment scoreAll on Score {
    ...scoreMain

    locales: scoreLocalesBySourceIdList {
      ...scoreLocalesAll
    }
  }

  ${scoreMain}
  ${scoreLocalesAll}
`;
