import React, { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import Table from 'components/Table';

import BaseSection from 'sections/BaseSection';

import {
  FETCH_COMPOSITIONS,
  DELETE_COMPOSITION,
} from '_graphql/actions/compositions';
import {
  findLocale,
  goToDetails,
  searchWithLocales,
} from 'helpers';
import { languages } from '_constants';

const tableColumns = [
  {
    title: 'Published',
    getValue: item => item.published ? 'yes' : 'no',
  },
  ...languages.map(lang => (
    {
      title: 'Title ' + lang.code,
      getValue: item => findLocale(item.locales, lang.code).title,
    }
  )),
];

function Compositions(props) {
  const [deleteCompositions, { loading: isDeleteLoading }] = useMutation(DELETE_COMPOSITION);

  const {
    history,
  } = props;

  const handleAddButtonClick = useCallback(row => {
    goToDetails(history, 'composition')(row);
  }, [history]);

  const handleDelete = useCallback(async compositionId => {
    await deleteCompositions({
      variables: {
        id: compositionId,
      },
      refetchQueries: ['FetchCompositions'],
    });
  }, [deleteCompositions]);

  return (
    <BaseSection
      title="Compositions"
      isLoading={isDeleteLoading}
      onAddButtonClick={handleAddButtonClick}
    >
      <Table
        dataKey="compositions"
        onDelete={handleDelete}
        tableColumns={tableColumns}
        onEdit={handleAddButtonClick}
        fetchQuery={FETCH_COMPOSITIONS}
        onRowFilter={searchWithLocales}
        onDoubleClick={handleAddButtonClick}
      />
    </BaseSection>
  );
}

Compositions.defaultProps = {};

Compositions.propTypes = {};

export default Compositions;
