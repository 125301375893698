export default [
  {
    code: 'hy',
    name: 'Հայերեն',
    isDefault: true,
  },
  {
    code: 'en',
    name: 'English',
  },
];
