import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.sass';

const PDFViewer = ({
 pdfUrl,
  className,
}) => (
  <object
    data={pdfUrl}
    type="application/pdf"
    className={classNames(className, 'pdf-viewer-wrapper')}
  >
    <b>Can't open PDF in your browser!</b>
    <p>
      <a
        download
        href={pdfUrl}
      >
        Click here to download the file.
      </a>
    </p>
  </object>
);

PDFViewer.propTypes = {
  pdfUrl: PropTypes.string,
};

PDFViewer.defaultProps = {
  pdfUrl: null,
};

export default PDFViewer;
