import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loading from 'components/Loading';

import Form, {
  FormSection,
} from 'components/Form';

import Grid from '@material-ui/core/Grid';

import LocaleTabs from 'components/LocaleTabs';

import {
  findLocale,
  renderFormField,
} from 'helpers';

import {
  FETCH_COMPOSITION_LOCALES,
  CREATE_COMPOSITION_LOCALE,
  UPDATE_COMPOSITION_LOCALE,
} from '_graphql/actions/compositions';

const fields = [
  {
    name: 'title',
    required: true,
    label: 'Composition Title',
  },
  {
    required: true,
    name: 'description',
    label: 'Composition Description',
  },
];

const refetchQueries = ['FetchCompositions'];

function CompositionLocales(props) {
  const { compositionId } = props;
  const { data: { composition = {} } = {}, loading } = useQuery(FETCH_COMPOSITION_LOCALES, {
    variables: {
      id: compositionId,
    },
  });

  const [createCompositionLocale, { loading: crtLoading }] = useMutation(CREATE_COMPOSITION_LOCALE);
  const [updateCompositionLocale, { loading: updLoading }] = useMutation(UPDATE_COMPOSITION_LOCALE);

  const handleFormSubmit = useCallback(locale => {
    const action = locale.nodeId
      ? updateCompositionLocale
      : createCompositionLocale;

    return action({
      variables: {
        compositionId: compositionId,
        ...locale,
      },
      refetchQueries,
    });
  }, [updateCompositionLocale, createCompositionLocale, compositionId]);

  return (
    <Loading isLoading={loading || crtLoading || updLoading}>
      <LocaleTabs
        renderContent={lang => {
          const locale = findLocale(composition.locales, lang.code);

          return (
            <Form
              title="data"
              key={lang.code}
              defaultData={locale}
              isEdit={!!locale.nodeId}
              onSubmit={handleFormSubmit}
              renderContent={(onFormChange, formData) => {
                return (
                  <FormSection>
                    <Grid container>
                      {fields.map(inp => renderFormField({ inp, formData, onFormChange, lang }))}
                    </Grid>
                  </FormSection>
                );
              }}
            />
          );
        }}
      />
    </Loading>
  );
}

CompositionLocales.defaultProps = {};

CompositionLocales.propTypes = {
  compositionId: PropTypes.number.isRequired,
};

export default CompositionLocales;
