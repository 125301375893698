import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { FormHeader } from 'components/Form';

const styles = theme => ({
  section: {
    padding: 20,
    width: '100%',
    borderLeft: `3px solid ${theme.palette.grey[200]}`,
  },
});

function FormSection(props) {
  const {
    title,
    classes,
    children,
    className,
  } = props;

  return (
    <>
      {title && (
        <FormHeader
          title={title}
        />
      )}

      <div
        className={classNames(classes.section, className)}
      >
        {children}
      </div>
    </>
  );
}

FormSection.defaultProps = {
  title: '',
};

FormSection.propTypes = {
  title: PropTypes.string,
};

export default withStyles(styles)(FormSection);
