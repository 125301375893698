import React from 'react';

import SimpleDataSection from 'containers/SimpleDataSection';

import {
  DELETE_INSTRUMENT,
  FETCH_INSTRUMENTS,
  ADD_EMPTY_INSTRUMENT,
  ADD_INSTRUMENT_LOCALE,
  UPDATE_INSTRUMENT_LOCALE,
} from '_graphql/actions/instruments';

function Genres() {
  return (
    <SimpleDataSection
      dataKey="instrument"
      sectionTitle="Instruments"
      queryName="FetchInstruments"
      fetchQuery={FETCH_INSTRUMENTS}
      addQuery={ADD_EMPTY_INSTRUMENT}
      deleteQuery={DELETE_INSTRUMENT}
      createActionKey="createInstrument"
      addLocaleQuery={ADD_INSTRUMENT_LOCALE}
      updateLocaleQuery={UPDATE_INSTRUMENT_LOCALE}
    />
  );
}

export default Genres;
