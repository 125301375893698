import Tags from './';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/tags',
    title: 'Tags',
    component: Tags,
  },
];

export default routes;
