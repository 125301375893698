import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ToastsStore } from 'react-toasts';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { FormSection } from 'components/Form';
import ImagesModal from 'components/ImagesModal';
import Image from 'components/Image';

import {
  ADD_ARTICLE_IMAGE,
  DELETE_ARTICLE_IMAGE,
  FETCH_ARTICLE_IMAGES,
  CREATE_ARTICLE_GALLERY,
  ADD_ARTICLE_GALLERY_IMAGE,
} from '_graphql/actions/articles';

function ArticleImages(props) {
  const {
    article,
    updateArticle,
  } = props;
  
  const { data = {}, loading } = useQuery(FETCH_ARTICLE_IMAGES, {
    variables: {
      id: article.id,
    }
  });

  const [addArticleImage, { loading: addImageLoading }] = useMutation(ADD_ARTICLE_IMAGE);
  // const [updateArticleImage, { loading: updateImageLoading }] = useMutation(UPDATE_ARTICLE_IMAGE);
  const [deleteArticleImage, { loading: deleteImageLoading }] = useMutation(DELETE_ARTICLE_IMAGE);
  const [addArticleGalleryImage, { loading: addArticleGalleryImageLoading }] = useMutation(ADD_ARTICLE_GALLERY_IMAGE);
  const [createArticleGallery, { loading: createArticleGalleryLoading }] = useMutation(CREATE_ARTICLE_GALLERY);

  const addImage = useCallback(image => {
    const variables = {
      articleId: article.id,
      images: [{ url: image, caption: '', description: '' }],
    };

    return addArticleImage({
      variables,
    });
  }, [addArticleImage, article]);

  const deleteImage = useCallback(image => {
    return deleteArticleImage({
      variables: {
        imageId: image.id,
        articleId: article.id,
      },
    });
  }, [deleteArticleImage, article]);

  // const updateImage = useCallback(image => {
  //   return updateArticleImage({
  //     variables: {
  //       imageId: image.id,
  //       articleId: article.id,
  //       captionEn: image.captionEn,
  //       captionHy: image.captionHy,
  //     },
  //   });
  // }, [article]);

  const setMainImage = useCallback(image => {
    return updateArticle({
      variables: {
        id: article.id,
        path: article.path,
        posterId: image && typeof image === 'object' ? image.id : null,
      },
    });
  }, [updateArticle, article]);

  const handleGalleryImagesAdd = useCallback(async (images) => {
    let { galleryId } = article;

    if (!galleryId) {
      const res = await createArticleGallery({
        variables: {
          id: article.id,
        },
      });

      if (!res || !res.data.createArticleGallery) {
        ToastsStore.error('Something went wrong during adding a new gallery.');
        return;
      } else {
        galleryId = res.data.createArticleGallery.articleGallery.id;
        await updateArticle({
          variables: {
            galleryId,
            id: article.id,
            path: article.path,
          }
        });
      }
    }

    images.forEach(async image => await addArticleGalleryImage({
      variables: {
        imageId: image.id,
        galleryId,
      },
      refetchQueries: ['FetchArticleGallery'],
    }));
  }, [addArticleGalleryImage, createArticleGallery, updateArticle, article]);

  const isImageLoading = loading
    || deleteImageLoading
    || addImageLoading
    || addArticleGalleryImageLoading
    || createArticleGalleryLoading;

  return (
    <FormSection title="Images">
      <ImagesModal
        multiple
        addImage={addImage}
        onAccept={setMainImage}
        removeImage={deleteImage}
        isLoading={isImageLoading}
        allSelectedBtnText="Add selected images to gallery"
        onMultipleImgaeSelectAccept={handleGalleryImagesAdd}
        images={data.article ? data.article.images.map(img => img.image) : []}
      />

      {article.poster && (
        <div className="margin-top-15">
          <Divider/>

          <Typography
            variant="h5"
          >
            Poster Image
          </Typography>

          <Image
            image={article.poster}
            src={article.poster.url}
            onDelete={() => setMainImage(null)}
          />
        </div>
      )}
    </FormSection>
  );
}

ArticleImages.defaultProps = {};

ArticleImages.propTypes = {
  article: PropTypes.shape({ id: PropTypes.number })
};

export default ArticleImages;
