import { gql } from 'apollo-boost';

export const tagLocaleAll = gql`
  fragment tagLocaleAll on TagLocale {
    nodeId
    name
    lang
  }
`;

export const tagAll = gql`
  fragment tagAll on Tag {
    id
    nodeId
    locales: tagLocalesBySourceIdList {
      ...tagLocaleAll
    }
  }
  ${tagLocaleAll}
`;
