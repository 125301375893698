import { gql } from 'apollo-boost';
import { articleLocalesAll } from '_graphql/fragments';

export const FETCH_ARTICLE_LOCALES = gql`
  query FetchArticleLocales($id: Int!) {
    article(id: $id) {
      locales: articleLocalesBySourceIdList {
        ...articleLocalesAll
      }
    }
  }

  ${articleLocalesAll}
`;

export const CREATE_ARTICLE_LOCALE = gql`
  mutation CreateArticleLocale(
    $articleId: Int!
    $lang: String!
    $content: String!
    $title: String!
    $description: String!
  ) {
    createArticleLocale(input: {
      articleLocale: {
        sourceId: $articleId
        description: $description
        content: $content
        title: $title
        lang: $lang
      }
    }
    ) {
      articleLocale {
        ...articleLocalesAll
      }
    }
  }

  ${articleLocalesAll}
`;

export const UPDATE_ARTICLE_LOCALE = gql`
  mutation UpdateArticleLocale(
    $articleId: Int!
    $lang: String!
    $title: String!
    $content: String!
    $description: String!
  ) {
    updateArticleLocale(input: {
      lang: $lang
      sourceId: $articleId
      patch: {
        description: $description
        sourceId: $articleId
        content: $content 
        title: $title
        lang: $lang
      }
    }
    ) {
      articleLocale {
        ...articleLocalesAll
      }
    }
  }

  ${articleLocalesAll}
`;
