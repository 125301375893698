const appBarHeight = 64;
const pad = 10;

export default {
  content: {
    padding: pad,
    top: appBarHeight - pad,
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    height: `calc(100vh - ${appBarHeight}px)`, // the app bar height
  },
};
