export const getHtmlContent = (articles, locale) => {
  return (`
    <!DOCTYPE html>
      <html lang="${locale}">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <title>Armenian National Music</title>
        <style>
          table tr td {
            padding: 8px
          }
        
          h4,
          h3 {
            text-align: center;
          }

          .welcome-title {
            color: #005CDB;
          }

          .welcome-subtitle {
            color: rgba(0, 0, 0, 0.54);
          }

          .anm-logo {
            width: 380px;
            height: 270px;
          }

          .social-icon {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            cursor: pointer;
            margin-right: 10px;
            border: 1px solid rgba(0, 0, 0, 0.03);
            transition: all .2s linear;
          }

          .social-icon-link {
            text-decoration: none;
          }

          .social-icon:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border: 1px solid rgba(0, 0, 0, 0.07);
          }

          .copyright-wrapper,
          .anm-info {
            margin-top: 10px;
            color: rgba(0, 0, 0, 0.54);
            text-align: center;
          }

          .news-wrapper {
            margin: 10px 0;
          }

          .news-item-row:hover {
              background-color: rgba(0, 0, 0, 0.06);
          }
          
          .news-item {
            margin-right: 10px;
            margin-top: 10px;
            padding: 10px;
            width: 400px;
            text-align: center;
          }

          .item-caption {
            color: #005CDB;
            margin: 0;
            margin-top: 8px;
          }

          .news-item-image {
            width: 230px;
            height: 200px;
            margin-bottom: 10px;
          }
        </style>
      </head>

      <body>
        <table width="800">
          <tbody>
            <tr>
              <td align="center">
                <img src="https://anmmedia.am/images/8d4f533b-e6b1-4e16-b6c0-4b849c817333.png"
                  alt="Armenian National Music Logo" class="anm-logo">
              </td>
            </tr>
            <tr>
              <td align="center">
                <h4 class="welcome-title">
                  ${locale === 'hy' ? 'Ողջու՜յն' : 'Hello'}
                </h4>
              </td>
            </tr>
            <tr>
              <td align="center">
                <h3 class="welcome-subtitle">
                  ${locale === 'hy' ? 'Սիրով ներկայացնում ենք մեր վերջին նորությունները' : 'Please check out our latest news'}
                </h3>
              </td>
            </tr>
            
              ${articles}
            
            <tr>
              <td align="center">
                <div class="social-icons">
                  <a href="https://www.facebook.com/Armeniannationalmusic" target="_blank" class="social-icon-link">
                    <img src="https://anmmedia.am/images/ea37ad60-8c7c-4fdd-933a-f103e9eb5afe.png" alt="" class="social-icon">
                  </a>
                  <a href="https://www.instagram.com/anmmedia/" target="_blank" class="social-icon-link">
                    <img src="https://anmmedia.am/images/c176d4dd-0f4d-431d-be0c-5f91eb5aba03.png" alt="" class="social-icon">
                  </a>
                  <a href="https://twitter.com/anmmedia" target="_blank" class="social-icon-link">
                    <img src="https://anmmedia.am/images/ad778e33-13d3-4958-8033-58b6e0155c94.png" alt="" class="social-icon">
                  </a>
                  <a href="https://www.youtube.com/channel/UC-FhmrVqnTUXQh31VXcru3g?sub_confirmation=1" target="_blank"
                    class="social-icon-link">
                    <img src="https://anmmedia.am/images/9388eac0-8ca0-49c6-82b9-422404682594.png" alt="" class="social-icon">
                  </a>
                </div>

                <div class="anm-info">
                  Armenian National Music
                  <br>
                  ${locale === 'hy' ? 'Ֆուչիկի փող։' : 'Fuchik str.'} 27/41,
                  <br>
                  0048 ${locale === 'hy' ? 'Երևան' : 'Yerevan'}
                  <br>
                  press@anmmedia.am
                  <br>
                  <br>
                  ${locale === 'hy' ? 'Կայք' : 'Website'}: <a href="https://anmmedia.am">https://anmmedia.am/${locale}</a>
                </div>

                <div class="copyright-wrapper">
                  © ${new Date().getFullYear()} Armenian National Music
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </body>

      </html>
  `);
};
