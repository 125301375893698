import { gql } from 'apollo-boost';

export const FETCH_DOCUMENTS = gql`
  query FetchDocuments {
    documents: documentsList {
      id
      name
      locales: documentLocalesBySourceIdList {
        lang
        nodeId
        content
      }
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($name: String!, $content: String!) {
    createDocument(input: {
      document: {
        name: $name
        content: $content
      }
    }) {
      document {
        nodeId
        id
      }
    }
  }
`;

export const CREATE_DOCUMENT_LOCALE = gql`
  mutation CreateDocumentLocale($id: Int!, $content: String!, $lang: String!) {
    createDocumentLocale(input: {
      documentLocale: {
        sourceId: $id,
        lang: $lang
        content: $content
      }
    }) {
      documentLocale {
        nodeId
        content
        lang
      }
    }
  }
`;

export const UPDATE_DOCUMENT_LOCALE = gql`
  mutation UpdateDocumentLocale($id: Int!, $content: String!, $lang: String!) {
    updateDocumentLocale(input: {
      patch: {
        content: $content
      },
      sourceId: $id
      lang: $lang
    }) {
      documentLocale {
        nodeId
        content
        lang
      }
    }
  }
`;
