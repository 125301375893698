import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import lodashCloneDeep from 'lodash.clonedeep';

import Grid from '@material-ui/core/Grid';

import PathInput from 'components/PathInput';
import GoBackPageButtonRow from 'components/GoBackPageButtonRow';
import Form, {
  FormSection,
} from 'components/Form';

import TagsSelect from 'containers/TagsSelect';
import GenresSelect from 'containers/GenresSelect';

import PublishRow from 'sections/PublishRow';
import ContentSection from 'sections/ContentSection';

import {
  checkPagePath, getSelectInputData,
} from 'helpers';

import withUser from 'hoc/withUser';

import ArticleImages from './ArticleImages';
import ArticleGallery from './ArticleGallery';
import ArticleLocales from './ArticleLocales';

import {
  ADD_ARTICLE,
  UPDATE_ARTICLE,
  FETCH_SINGLE_ARTICLE,
} from '_graphql/actions';

function ArticlesDetails(props) {
  const {
    isAdmin,
    match: { params },
  } = props;
  
  const { data, loading } = useQuery(FETCH_SINGLE_ARTICLE, {
    variables: {
      id: Number(params.articleId),
    },
    skip: !params.articleId,
  });

  const [addArticle, { data: newData = {}, loading: addArticleLoading }] = useMutation(ADD_ARTICLE, {
    onCompleted: ({ createArticle: { article: newArticle } }) => {
      props.history.replace(`/articles/details/${newArticle.id}`);
    },
  });

  const [updateArticle, { loading: updateArticleLoading }] = useMutation(UPDATE_ARTICLE);

  const { article: newArticle = {} } = lodashCloneDeep((newData.createArticle || data || {}));
  const article = {
    ...newArticle,
    tags: getSelectInputData(newArticle.tags),
    genres: getSelectInputData(newArticle.genres),
  };

  const isEdit = !!(article && article.id);

  const handleFormSubmit = useCallback(async newData => {
    if (checkPagePath(newData.path)) {
      const action = isEdit
        ? updateArticle
        : addArticle;

      return action({
        variables: newData,
        refetchQueries: ['FetchArticles'],
      });
    }
  }, [updateArticle, isEdit, addArticle]);

  const handleArticlePublish = useCallback(publishData => {
    return updateArticle({
      variables: {
        id: article.id,
        path: article.path,
        ...publishData
      },
    });
  }, [updateArticle, article]);

  const isLoading = loading
    || updateArticleLoading
    || addArticleLoading;

  return (
    <ContentSection
      isLoading={isLoading}
    >
      <GoBackPageButtonRow
        goTo="/articles"
      />

      {article.id && (
        <>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <PublishRow
              published={article.published}
              onUpdate={handleArticlePublish}
              publishedAt={article.publishedAt}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ArticleImages
              article={article}
              updateArticle={updateArticle}
            />
          </Grid>
        </Grid>

          <ArticleLocales
            articleId={article.id}
          />
        </>
      )}

      <Form
        isEdit={isEdit}
        title="Details"
        key={article.nodeId}
        isLoading={isLoading}
        defaultData={article}
        onSubmit={handleFormSubmit}
        renderContent={(onFormChange, formData) => {
          return (
            <FormSection>
              <PathInput
                onChange={onFormChange}
                value={formData.path || ''}
                disabled={!isAdmin && !!article.id}
              />

              <GenresSelect
                required
                onChange={onFormChange}
                value={formData.genres}
              />

              <TagsSelect
                required
                value={formData.tags}
                onChange={onFormChange}
              />
            </FormSection>
          );
        }}
      />

      {article.galleryId && (
        <ArticleGallery
          gallery={article.gallery}
          galleryId={article.galleryId}
        />
      )}
    </ContentSection>
  );
}

export default withUser(ArticlesDetails);
