import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { removeMessage } from '_constants';

function DeleteButton(props) {
  const {
    onClick,
  } = props;

  function handleOnClick(event) {
    event.stopPropagation();

    if (window.confirm(removeMessage)) {
      onClick();
    }
  }

  return (
    <IconButton
      edge="end"
      size="small"
      color="secondary"
      aria-label="Delete"
      onClick={handleOnClick}
    >
      <DeleteIcon />
    </IconButton>
  );
}

DeleteButton.defaultProps = {};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DeleteButton;
