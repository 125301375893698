import React, { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Avatar from '@material-ui/core/Avatar';
import dateFormat from 'dateformat';
import Table from 'components/Table';

import BaseSection from 'sections/BaseSection';


import { FETCH_SCORES, DELETE_SCORE } from '_graphql/actions';
import {
  findLocale,
  goToDetails,
  searchWithLocales,
  addImageProportions,
} from 'helpers';
import { languages } from '_constants';

const format = 'dd-mm-yyyy';
const orderTypes = [
  {
    label: 'Natural',
    value: 'NATURAL',
  },
  {
    label: 'Created At ASC',
    value: 'CREATED_AT_ASC',
  },
  {
    label: 'Created At DESC',
    value: 'CREATED_AT_DESC',
  },
  {
    label: 'Published At ASC',
    value: 'PUBLISHED_AT_ASC',
  },
  {
    label: 'Published At DESC',
    value: 'PUBLISHED_AT_DESC',
  },
  {
    label: 'Updated At ASC',
    value: 'UPDATED_AT_ASC',
  },
  {
    label: 'Updated At DESC',
    value: 'UPDATED_AT_DESC',
  },
];

const defaultOrderType = 'CREATED_AT_DESC';

const defaultVariables = {
  orderBy: defaultOrderType,
};

const tableColumns = [
  {
    title: 'Poster',
    getValue: item => item.poster
      ? <Avatar src={addImageProportions(item.poster, 80, 80)} />
      : '',
  },
  {
    title: 'Created at',
    getValue: item => dateFormat(item.createdAt, format),
  },
  {
    title: 'Updated at',
    getValue: item => dateFormat(item.updatedAt, format),
  },
  {
    title: 'Composer',
    getValue: item => {
      const { composition } = item;
      const { musiciansList = [] } = (composition || {});
      return musiciansList.map(composer => `${composer.firstName} ${composer.lastName}`).join(' - ');
    },
  },
  ...languages.map(lang => (
    {
      title: 'Title ' + lang.code,
      getValue: item => findLocale(item.locales, lang.code).title,
    }
  )),
];

function Scores (props) {
  const [deleteScore, { loading: isDeleteLoading }] = useMutation(DELETE_SCORE);

  const {
    history,
  } = props;

  const handleAddButtonClick = useCallback(row => {
    goToDetails(history, 'score')(row);
  }, [history]);

  const handleDelete = useCallback(async articleId => {
    await deleteScore({
      variables: {
        id: articleId,
      },
      refetchQueries: ['FetchScores'],
    });
  }, [deleteScore]);

  return (
    <BaseSection
      title="Scores"
      isLoading={isDeleteLoading}
      onAddButtonClick={handleAddButtonClick}
    >
      <Table
        dataKey="scores"
        onDelete={handleDelete}
        orderOptions={orderTypes}
        fetchQuery={FETCH_SCORES}
        tableColumns={tableColumns}
        onEdit={handleAddButtonClick}
        onRowFilter={searchWithLocales}
        defaultVariables={defaultVariables}
        defaultOrderType={defaultOrderType}
        onDoubleClick={handleAddButtonClick}
      />
    </BaseSection>
  );
}

Scores.defaultProps = {};

Scores.propTypes = {};

export default Scores;
