import { gql } from 'apollo-boost';
import { compositionLocaleAll } from '_graphql/fragments/compositions';

export const FETCH_COMPOSITION_LOCALES = gql`
  query FetchCompositionLocales($id: Int!) {
    composition(id: $id) {
      locales: compositionLocalesBySourceIdList {
        ...compositionLocaleAll
      }
    }
  }

  ${compositionLocaleAll}
`;

export const CREATE_COMPOSITION_LOCALE = gql`
  mutation CreateCompositionLocale(
    $compositionId: Int!
    $lang: String!
    $title: String!
    $description: String
  ) {
    createCompositionLocale(input: {
      compositionLocale: {
        sourceId: $compositionId
        description: $description
        title: $title
        lang: $lang
      }
    }
    ) {
      compositionLocale {
        ...compositionLocaleAll
      }
    }
  }

  ${compositionLocaleAll}
`;

export const UPDATE_COMPOSITION_LOCALE = gql`
  mutation UpdateCompositionLocale(
    $compositionId: Int!
    $lang: String!
    $title: String!
    $description: String
  ) {
    updateCompositionLocale(input: {
      lang: $lang
      sourceId: $compositionId
      patch: {
        description: $description
        sourceId: $compositionId
        title: $title
        lang: $lang
      }
    }
    ) {
      compositionLocale {
        ...compositionLocaleAll
      }
    }
  }

  ${compositionLocaleAll}
`;
