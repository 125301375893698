export default [
  {
    label: 'Composer',
    value: 'composer',
  },
  {
    label: 'Artist',
    value: 'artist',
  }
]
