import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import BaseSection from 'sections/BaseSection';

import TermsPrivacyDetails from './TermsPrivacyDetails';

import {
  FETCH_DOCUMENTS,
} from '_graphql/actions/termsAndPrivacy';

// import './styles.sass';

const sections = [
  {
    title: 'Terms',
    value: 'terms',
  },
  {
    title: 'Privacy & Policy',
    value: 'privacy',
  },
];

function TermsAndPrivacy() {
  const { data: { documents = [] } = {}, loading } = useQuery(FETCH_DOCUMENTS);
  const [selectedTab, setTab] = useState(sections[0].value);

  const currentDocument = documents.find(doc => doc.name === selectedTab);

  return (
    <BaseSection
      isLoading={loading}
      title="Terms and Privacy & Policy"
    >
      <Tabs
        color="primary"
        variant="fullWidth"
        value={selectedTab}
        indicatorColor="primary"
        onChange={(event, value) => setTab(value)}
      >
        {
          sections.map(section => {
            return (
              <Tab
                key={section.value}
                label={section.title}
                value={section.value}
              />
            );
          })
        }
      </Tabs>

      <TermsPrivacyDetails
        key={selectedTab}
        section={selectedTab}
        sectionDocument={currentDocument}
      />
    </BaseSection>
  );
}

TermsAndPrivacy.defaultProps = {};

TermsAndPrivacy.propTypes = {};

export default TermsAndPrivacy;
