import React from 'react';
import { TextField } from 'components/Form';

function PathInput(props) {
  const {
    value,
  } = props;

  return (
    <TextField
      required
      fullWidth
      name="path"
      value={value || ''}
      label="Path (relative url)"
      helperText="It should contain only lower case letters and hyphens. e.g 'aram-khachaturyan-concert-in-yerevan'."
      {...props}
    />
  );
}

PathInput.defaultProps = {};

PathInput.propTypes = {};

export default PathInput;
