import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function GoBackPageButtonRow(props) {
  const {
    goTo,
    history,
    onButtonClick,
  } = props;

  const handleFormCancel = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      history.replace(goTo);
    }
  }, [goTo, onButtonClick, history]);

  return (
    <div className="margin-top-15">
      <Button
        aria-label="back"
        onClick={handleFormCancel}
      >
        <ArrowBackIcon
          fontSize="small"
        />
        Go Back
      </Button>
    </div>
  );
}

GoBackPageButtonRow.defaultProps = {};

GoBackPageButtonRow.propTypes = {
  goTo: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default withRouter(GoBackPageButtonRow);
