import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import lodashGet from 'lodash.get';

import Loading from 'components/Loading';
import {
  Select,
} from 'components/Form';

import {
  FETCH_INSTRUMENTS,
} from '_graphql/actions/instruments';
import { generateSelectLabel } from 'containers/helpers';

function InstrumentsSelect(props) {
  const { data = {}, loading } = useQuery(FETCH_INSTRUMENTS);

  const options = generateSelectLabel(lodashGet(data, 'instruments.nodes', []));

  return (
    <Loading isLoading={loading}>
      <Select
        isMulti
        name="instruments"
        label="Instruments"
        options={options}
        {...props}
      />
    </Loading>
  );
}

InstrumentsSelect.defaultProps = {};

InstrumentsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default InstrumentsSelect;
