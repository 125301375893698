import { gql } from 'apollo-boost';

export const professionLocaleAll = gql`
  fragment professionLocaleAll on ProfessionLocale {
    nodeId
    name
    lang
  }
`;

export const professionAll = gql`
  fragment professionAll on Profession {
    id
    nodeId
    locales: professionLocalesBySourceIdList {
      ...professionLocaleAll
    }
  }
  ${professionLocaleAll}
`;
