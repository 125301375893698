import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import xor from 'lodash.xor';

import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import withUser from 'hoc/withUser';

import { sidebarRoutes } from 'routes';
import SidebarItem from '../SidebarItem';

import styles from './styles';

const isSamePath = (location, item) => location.pathname.includes(item.path);

const renderItem = itemProps => {
  const {
    item,
    hasChild,
    isSelected,
    handleItemClick,
    isNestedChild = false,
  } = itemProps;

  const ItemIcon = item.icon || (() => null);

  return (
    <SidebarItem
      onClick={() => handleItemClick && handleItemClick(item)}
      isNestedChild={isNestedChild}
      isSelected={isSelected}
      hasChild={hasChild}
      ItemIcon={ItemIcon}
      title={item.title}
      key={item.title}
    />
  );
};

function SidebarMenu(props) {
  const {
    classes,
    history,
    location,
    onItemClick,
  } = props;
  const [openedItems, setOpenedItems] = useState([]);

  useEffect(() => {
    // Add default opened item on render
    const openedItems = sidebarRoutes
      .filter(item => item.routes && item.routes.some(el => location.pathname.includes(el.path)))
      .map(item => item.title);
    setOpenedItems(openedItems);
  }, [setOpenedItems, location]);

  const handleItemClick = (item) => {
    history.push(item.path);
    onItemClick && onItemClick(item);
  };

  const toggleOpenedItems = item => {
    setOpenedItems(xor(openedItems, [item.title]));
  };

  return (
    <List className={classes.mainList}>
      {
        sidebarRoutes.map((item, index) => {
          if (!item.routes) {
            return renderItem({
              item,
              handleItemClick,
              isSelected: isSamePath(location, item),
            });
          }

          return (
            <div key={item.title}>
              {
                renderItem({
                  item,
                  hasChild: true,
                  handleItemClick: toggleOpenedItems,
                })
              }

              <Collapse
                unmountOnExit
                in={openedItems.includes(item.title)}
                key={String(index + item.title)}
                className={classNames(classes.sidebarCollapsableItem)}
              >
                <List
                  component="div"
                  disablePadding
                >
                  {item.routes.map(el => renderItem({
                    item: el,
                    handleItemClick,
                    isNestedChild: true,
                    isSelected: isSamePath(location, el),
                  }))}
                </List>
              </Collapse>
            </div>
          );
        })
      }
    </List>
  );
}

export default withRouter(
  withStyles(styles)(withUser(SidebarMenu))
);
