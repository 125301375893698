import { gql } from 'apollo-boost';

export const instrumentLocaleAll = gql`
  fragment instrumentLocaleAll on InstrumentLocale {
    lang
    name
    nodeId
  }
`;


export const instrumentAll = gql`
  fragment instrumentAll on Instrument {
    id
    nodeId
    locales: instrumentLocalesBySourceIdList {
      ...instrumentLocaleAll
    }
  }
  
  ${instrumentLocaleAll}
`;
