import Scores from './index';
import ScoreDetails from './ScoreDetails';
import ScoreIcon from '@material-ui/icons/Score';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/scores',
    title: 'Scores',
    component: Scores,
    icon: ScoreIcon,
  },
  {
    path: '/score/details/:scoreId?',
    title: 'Score Details',
    component: ScoreDetails,
  },
];

export default routes;
