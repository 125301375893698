import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';

import Img from 'components/Img';

import { copyToClipboard } from 'helpers';
import { removeMessage } from '_constants';

const styles = theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  paper: {
    border: '5px solid transparent',
    overflow: 'hidden',
    position: 'relative',
    '&:hover:not(.selected)': {
      cursor: 'pointer',
      boxShadow: theme.shadows[4],
    },
    '&:hover $imageActions': {
      bottom: 5,
      visibility: 'visible',
    },
    '&.selected': {
      borderColor: theme.palette.primary.light,
    },
    backgroundColor: 'rgba(248, 248, 248)',
  },
  imageActions: {
    backgroundColor: 'rgba(255,255,255, .3)',
    transition: ['bottom', 'visibility'],
    justifyContent: 'space-around',
    transitionDuration: 300,
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    visibility: 'hidden',
    display: 'flex',
    width: '100%',
    height: 50,
    bottom: -60,
    left: 0,
  },
});

function Image(props) {
  const {
    src,
    file,
    size,
    image,
    onEdit,
    classes,
    onClick,
    onDelete,
    selected,
    onUrlCopy,
    className,
  } = props;

  const fileSrc = file ? URL.createObjectURL(file) : `${src}?width=${size}&height=${size}`;

  function handleClick() {
    onClick && onClick(image);
  }

  function handleUrlCopy() {
    onUrlCopy && onUrlCopy(src, image);
    return copyToClipboard(src);
  }

  function handleDelete() {
    if (window.confirm(removeMessage)) {
      onDelete(image, src);
    }
  }

  return (
    <div
      onClick={handleClick}
      style={{ width: size, height: size }}
      className={classNames(classes.paper, className, { selected })}
    >
      <Img
        src={fileSrc}
      />

      {(onEdit || onDelete || onUrlCopy) && (
        <div className={classes.imageActions}>
          {onEdit && (
            <Fab
              size="small"
              color="primary"
              aria-label="Edit"
              onClick={onEdit}
              className={classes.fab}
            >
              <Icon>edit_icon</Icon>
            </Fab>
          )}

          {onDelete && (
            <Fab
              size="small"
              color="secondary"
              aria-label="Delete"
              className={classes.fab}
              onClick={handleDelete}
            >
              <Icon>delete</Icon>
            </Fab>
          )}

          {
            onUrlCopy && (
              <Fab
                size="small"
                color="primary"
                aria-label="FileCopy"
                className={classes.fab}
                onClick={handleUrlCopy}
              >
                <Icon>file_copy</Icon>
              </Fab>
            )
          }
        </div>
      )}
    </div>
  );
}

Image.defaultProps = {
  src: '',
  size: 250,
  className: '',
  selected: false,
  onUrlCopy: null,
};

Image.propTypes = {
  src: PropTypes.string,
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  onDelete: PropTypes.func,
  onUrlCopy: PropTypes.func,
  file: PropTypes.shape({}),
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default withStyles(styles)(Image);
