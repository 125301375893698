import { gql } from 'apollo-boost';

export const FETCH_IMAGES = gql`
  query FetchImages($offset: Int) {
    images(orderBy: ID_DESC offset: $offset first: 10) {
      nodes {
        id
        url
        nodeId
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const CREATE_IMAGE = gql`
  mutation CreateImage($file: Upload!) {
    createImage(input: {
      image: {
        url: $file
      }
    }) {
      image {
        id
        url
        nodeId
        caption
        description
      }
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation DeleteImage($id: Int!) {
    deleteImage(input: { id: $id }) {
      image {
        id
        nodeId
      }
    }
  }
`;
