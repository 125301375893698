import React, { useContext, useState } from 'react';

import withUser from 'hoc/withUser';

import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Loading from 'components/Loading';

import { AuthContext } from 'pages/Auth/AuthProvider';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

function UserMenu() {
  const [isMenuOpen, setMenuOpenStatus] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logOut, isUserLoading } = useContext(AuthContext);
  
  function openMenu(event) {
    setAnchorEl(event.currentTarget);
    setMenuOpenStatus(true);
  }
  
  function closeMenu() {
    setAnchorEl(null);
    setMenuOpenStatus(false);
  }
  
  async function handleLogout() {
    await logOut();
    window.location.reload();
  }
  
  if (isUserLoading) {
    return <Loading isLoading />
  }
  
  if (!user) {
    return null;
  }
  
  return (
    <>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
      
      <div
        onClick={openMenu}
        className="pointer with-hover-grey flex-row align-center pad-15"
      >
        <AccountCircle className="mrg-sides-10"/>
        
        {user.firstName}
      </div>
    </>
  );
}

export default withUser(UserMenu);
