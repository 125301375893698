import { gql } from 'apollo-boost';
import { imageAll } from '_graphql/fragments/images';

const ArticleImageFragment = gql`
  fragment ArticleImageFragment on Article {
    id
    nodeId
    images: articleImagesList {
      image {
        ...imageAll
      }
    }
  }

  ${imageAll}
`;

export const FETCH_ARTICLE_IMAGES = gql`
  query FetchArticleImages($id: Int!) {
    article(id: $id) {
      ...ArticleImageFragment
    }
  }

  ${ArticleImageFragment}
`;

export const ADD_ARTICLE_IMAGE = gql`
  mutation AddArticleImage(
    $articleId: Int!
    $images: [ImageInput!]
  ) {
    updateArticle(input: {
      patch: {}
      id: $articleId
      createImages: $images
    }){
      article {
        ...ArticleImageFragment
      }
    }
  }

  ${ArticleImageFragment}
`;

export const UPDATE_ARTICLE_IMAGE = gql`
  mutation UpdateArticleImage(
    $articleId: Int!
    $imageId: Int!
    $caption: String
    $description: String
  ) {
    updateArticleImage(
      input: {
        patch: {
          image: {
            updateById: {
              patch: {
                caption: $caption
                description: $description
              },
              id: $imageId
            }
          }
        }
        articleId: $articleId
        imageId: $imageId
      }
    ){
      image {
        ...imageAll
      }
    }
  }

  ${imageAll}
`;

export const DELETE_ARTICLE_IMAGE = gql`
  mutation DeleteArticleImage(
    $articleId: Int!
    $imageId: Int!
  ) {
    deleteArticleImage(input:  { imageId: $imageId, articleId: $articleId }){
      article {
        ...ArticleImageFragment
      }
    }
  }

  ${ArticleImageFragment}
`;
