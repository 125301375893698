import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'components/Loading';

import styles from './styles';

function ContentSection(props) {
  return (
    <Loading isLoading={props.isLoading}>
      <div className={props.classes.content}>
        {props.children}
      </div>
    </Loading>
  );
}

ContentSection.defaultProps = {
  isLoading: false,
};

ContentSection.propTypes = {
  isLoading: PropTypes.bool,
};

export default withStyles(styles)(ContentSection);
