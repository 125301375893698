import { gql } from 'apollo-boost';
import { compositionAll } from '_graphql/fragments/compositions';

export const FETCH_COMPOSITIONS = gql`
  query FetchCompositions($orderBy: [CompositionsOrderBy!], $limit: Int, $offset: Int) {
    compositions(orderBy: $orderBy, first: $limit, offset: $offset) {
      nodes {
        ...compositionAll
      }
      totalCount
    }
  }

  ${compositionAll}
`;

export const FETCH_SINGLE_COMPOSITION = gql`
  query FetchSingleComposition ($id: Int!) {
    composition(id: $id) {
      ...compositionAll
    }
  }

  ${compositionAll}
`;

export const ADD_COMPOSITION = gql`
  mutation AddComposition {
    createComposition(
      input: {
        composition: {
          published: false
        }
      }) {
      composition {
        ...compositionAll
      }
    }
  }

  ${compositionAll}
`;

export const UPDATE_COMPOSITION = gql`
  mutation UpdateComposition(
    $id: Int!
    $published: Boolean
    $publishedAt: Datetime
  ) {
    updateComposition(
      input: {
        id: $id
        patch: {
          published: $published
          publishedAt: $publishedAt
        }
      }
    ) {
      composition {
        ...compositionAll
      }
    }
  }

  ${compositionAll}
`;

export const DELETE_COMPOSITION = gql`
  mutation DeleteComposition($id: Int!) {
    deleteComposition(input: { id: $id }) {
      composition {
        id
        nodeId
      }
    }
  }
`;
