import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import lodashXor from 'lodash.xor';
import dateFormat from 'dateformat';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';

import Table from 'components/Table';
import { goToDetails } from 'helpers/router/goToDetails';

import BaseSection from 'sections/BaseSection';

import {
  findLocale,
  copyToClipboard,
  searchWithLocales,
  addImageProportions,
} from 'helpers';

import {
  FETCH_ARTICLES,
  DELETE_ARTICLE,
} from '_graphql/actions/articles';

import { languages } from '_constants';
import { getHtmlContent } from './htmlHelper';

const orderTypes = [
  {
    label: 'Natural',
    value: 'NATURAL',
  },
  {
    label: 'Created At ASC',
    value: 'CREATED_AT_ASC',
  },
  {
    label: 'Created At DESC',
    value: 'CREATED_AT_DESC',
  },
  {
    label: 'Published At ASC',
    value: 'PUBLISHED_AT_ASC',
  },
  {
    label: 'Published At DESC',
    value: 'PUBLISHED_AT_DESC',
  },
  {
    label: 'Updated At ASC',
    value: 'UPDATED_AT_ASC',
  },
  {
    label: 'Updated At DESC',
    value: 'UPDATED_AT_DESC',
  },
];

const defaultOrderType = 'CREATED_AT_DESC';

const defaultVariables = {
  orderBy: defaultOrderType,
};
const format = 'dd-mm-yyyy';

const getArticleTemplate = (article, locale) => `
  <tr class="news-item-row">
    <td align="center">
      <a href="https://anmmedia.am/${locale}/news/${article.path}" target="_blank">
      <div class="news-item">
        <img src="${article.poster.url}" alt="" class="news-item-image">
      </div>
      <p class="item-caption">
        ${findLocale(article.locales, locale).title}
      </p>
    </a>
   </td>
  </tr>
`;

function Articles() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteArticle, { loading: deleteLoading }] = useMutation(DELETE_ARTICLE);

  const history = useHistory();

  const handleAddButtonClick = useCallback(row => {
    goToDetails(history, 'articles')(row);
  }, [history]);

  const handleDelete = useCallback(async articleId => {
    await deleteArticle({
      variables: {
        id: articleId,
      },
      refetchQueries: ['FetchArticles'],
    });
  }, [deleteArticle]);

  const handleItemSelect = useCallback(item => {
    setSelectedItems(lodashXor(selectedItems, [item]));
  }, [selectedItems, setSelectedItems]);

  const tableColumns = [
    {
      title: '',
      getValue: item => (
        <Checkbox
          onChange={() => handleItemSelect(item)}
          checked={!!selectedItems.find(row => row.id === item.id)}
        />
      ),
    },
    {
      title: 'Poster',
      getValue: item => item.poster
        ? <Avatar src={addImageProportions(item.poster.url, 80, 80)}/>
        : '',
    },
    {
      title: 'Published',
      getValue: item => item.published ? dateFormat(item.publishedAt, format) : 'no',
    },
    ...languages.map(lang => (
      {
        title: 'Title ' + lang.code,
        getValue: item => findLocale(item.locales, lang.code).title,
      }
    )),
  ];

  const copySelectedArticles = useCallback(locale => {
    let selectedItemsTemplate = '';

    for (let i = 0; i < selectedItems.length; i++) {
      selectedItemsTemplate += getArticleTemplate(selectedItems[i], locale);
    }

    copyToClipboard(getHtmlContent(selectedItemsTemplate, locale));
  }, [selectedItems]);

  return (
    <BaseSection
      title="Articles"
      isLoading={deleteLoading}
      onAddButtonClick={handleAddButtonClick}
    >
      <div className="flex-row justify-end">
        <Button
          color="primary"
          disabled={!selectedItems.length}
          onClick={() => copySelectedArticles('en')}
        >
          Copy En Template
        </Button>
        <Button
          color="primary"
          disabled={!selectedItems.length}
          onClick={() => copySelectedArticles('hy')}
        >
          Copy Hy Template
        </Button>
      </div>
      <Table
        dataKey="articles"
        onDelete={handleDelete}
        orderOptions={orderTypes}
        fetchQuery={FETCH_ARTICLES}
        tableColumns={tableColumns}
        onEdit={handleAddButtonClick}
        onRowFilter={searchWithLocales}
        defaultVariables={defaultVariables}
        defaultOrderType={defaultOrderType}
        onDoubleClick={handleAddButtonClick}
      />
    </BaseSection>
  );
}

export default Articles;
