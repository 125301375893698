import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';

const styles = theme => ({
  actionsWrapper: {
    padding: 10,
    width: '100%',
    display: 'flex',
    borderRadius: 0,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'flex-end',
    borderLeft: `3px solid ${theme.palette.grey[200]}`,
    borderBottom: `3px solid ${theme.palette.grey[200]}`,
  },
  actionButton: {
    marginLeft: 15,
  },
});

function FormActions(props) {
  const {
    classes,
    canSave,
    onSubmit,
    onCancel,
    disabled,
    saveButtonText,
    cancelButtonText,
    additionalButtons,
  } = props;

  return (
    <div className={classes.actionsWrapper}>
      {
        !!additionalButtons.length
        && additionalButtons.map(({ className = '', ...buttonProps }, index) => {
          return (
            <Button
              {...buttonProps}
              key={index}
              className={`${className} ${classes.actionButton}`}
            />
          );
        })
      }

      {cancelButtonText && onCancel && (
        <Button
          className={classes.actionButton}
          onClick={onCancel}
          variant="contained"
          disabled={disabled}
        >
          {cancelButtonText}
        </Button>
      )}

      <Button
        className={classes.actionButton}
        disabled={disabled || !canSave}
        onClick={onSubmit}
        color="primary"
        type="submit"
      >
        {saveButtonText}
      </Button>
    </div>
  );
}

FormActions.defaultProps = {
  canSave: true,
  onSubmit: null,
  onCancel: null,
  disabled: false,
  saveButtonText: 'Save',
  cancelButtonText: 'Cancel',
};

FormActions.propTypes = {
  canSave: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

export default withStyles(styles)(FormActions);
