import React from 'react';

import SimpleDataSection from 'containers/SimpleDataSection';

import {
  DELETE_GENRE,
  FETCH_GENRES,
  ADD_EMPTY_GENRE,
  ADD_GENRE_LOCALE,
  UPDATE_GENRE_LOCALE,
} from '_graphql/actions/genres';

function Genres() {
  return (
    <SimpleDataSection
      dataKey="genre"
      sectionTitle="Genres"
      queryName="FetchGenres"
      fetchQuery={FETCH_GENRES}
      addQuery={ADD_EMPTY_GENRE}
      deleteQuery={DELETE_GENRE}
      createActionKey="createGenre"
      addLocaleQuery={ADD_GENRE_LOCALE}
      updateLocaleQuery={UPDATE_GENRE_LOCALE}
    />
  );
}

export default Genres;
