import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import lodashGet from 'lodash.get';

import Loading from 'components/Loading';
import {
  Select,
} from 'components/Form';

import {
  FETCH_TAGS,
} from '_graphql/actions/tags';
import { generateSelectLabel } from 'containers/helpers';

function TagsSelect(props) {
  const { data, loading } = useQuery(FETCH_TAGS);

  const options = generateSelectLabel(lodashGet(data, 'tags.nodes', []));

  return (
    <Loading isLoading={loading}>
      <Select
        isMulti
        name="tags"
        label="Tags"
        options={options}
        {...props}
      />
    </Loading>
  );
}

TagsSelect.defaultProps = {};

TagsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TagsSelect;
