import React from 'react';

import SimpleDataSection from 'containers/SimpleDataSection';

import {
  DELETE_TAG,
  FETCH_TAGS,
  ADD_EMPTY_TAG,
  ADD_TAG_LOCALE,
  UPDATE_TAG_LOCALE,
} from '_graphql/actions/tags';

function Tags() {
  return (
    <SimpleDataSection
      dataKey="tag"
      sectionTitle="Tags"
      queryName="FetchTags"
      fetchQuery={FETCH_TAGS}
      addQuery={ADD_EMPTY_TAG}
      deleteQuery={DELETE_TAG}
      createActionKey="createTag"
      addLocaleQuery={ADD_TAG_LOCALE}
      updateLocaleQuery={UPDATE_TAG_LOCALE}
    />
  );
}

export default Tags;
