import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

function EditButton(props) {
  const {
    onClick,
  } = props;

  function handleOnClick(event) {
    event.stopPropagation();
    onClick();
  }

  return (
    <IconButton
      edge="end"
      size="small"
      color="primary"
      aria-label="Edit"
      onClick={handleOnClick}
      {...props}
    >
      <EditIcon />
    </IconButton>
  );
}

EditButton.defaultProps = {};

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EditButton;
