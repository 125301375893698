import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useMutation,
  useLazyQuery,
} from '@apollo/react-hooks';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ImagesModal from 'components/ImagesModal';
import { FormSection } from 'components/Form';
import Image from 'components/Image';

import {
  ADD_MUSICIAN_IMAGE,
  DELETE_MUSICIAN_IMAGE,
  FETCH_MUSICIAN_IMAGES,
} from '_graphql/actions/musicians';

import {
  CREATE_IMAGE,
} from '_graphql/actions/images';

function MusicianImages(props) {
  const {
    musician,
    updateMusician,
  } = props;
  const [fetchMusicianImages, { data = {}, loading }] = useLazyQuery(FETCH_MUSICIAN_IMAGES, {
    variables: {
      id: props.musician.id,
    }
  });
  
  const [createImage, { loading: isCreateImageLoading }] = useMutation(CREATE_IMAGE);
  const [addMusicianImage, { loading: addImageLoading }] = useMutation(ADD_MUSICIAN_IMAGE);
  const [deleteMusicianImage, { loading: deleteImageLoading }] = useMutation(DELETE_MUSICIAN_IMAGE);
  
  useEffect(() => {
    fetchMusicianImages();
  }, [fetchMusicianImages, musician]);
  
  const addImage = useCallback(async image => {
    if (image) {
      const newImage = await createImage({
        variables: {
          file: image,
        }
      });
      
      if (newImage.data.createImage && newImage.data.createImage.image) {
        await addMusicianImage({
          variables: {
            musicianId: musician.id,
            imageId: newImage.data.createImage.image.id,
          },
        });
        
        fetchMusicianImages();
      }
    }
  }, [createImage, fetchMusicianImages, addMusicianImage, musician]);
  
  const deleteImage = useCallback(image => {
    return deleteMusicianImage({
      variables: {
        imageId: image.id,
        musicianId: musician.id,
      },
    });
  }, [deleteMusicianImage, musician]);
  
  const setMainImage = useCallback(image => {
    return updateMusician({
      variables: {
        ...musician,
        photoId: image && typeof image === 'object' ? image.id : null,
      },
    });
  }, [updateMusician, musician]);
  
  const isImageLoading = loading
    || isCreateImageLoading
    || addImageLoading
    || deleteImageLoading;
  
  return (
    <FormSection title="Images">
      <ImagesModal
        multiple
        addImage={addImage}
        onAccept={setMainImage}
        removeImage={deleteImage}
        isLoading={isImageLoading}
        images={data.musician ? data.musician.images.map(img => img.image) : []}
      />
      
      {musician.photo && (
        <div className="margin-top-15">
          <Divider/>
          
          <Typography
            variant="h5"
          >
            Main Image
          </Typography>
          
          <Image
            image={musician.photo}
            src={musician.photo.url}
            onDelete={() => setMainImage(null)}
          />
        </div>
      )}
    </FormSection>
  );
}

MusicianImages.defaultProps = {};

MusicianImages.propTypes = {
  musician: PropTypes.shape({ id: PropTypes.number })
};

export default MusicianImages;
