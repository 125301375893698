import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DefaultImage from 'images/defaultImage.svg';

const styles = {
  image: {
    width: '100%',
    height: '100%',
    filter: 'blur(3px)',
  },
  loadedImage: {
    filter: 'unset',
  },
  defaultImage: {
    filter: 'blur(4px)',
  },
};

// Load placeholder
const loadedImages = new Set();

function Img(props) {
  const [image, setImage] = useState({ src: props.src || DefaultImage });

  const {
    src,
    onLoad,
    classes,
    className,
    ...imageProps
  } = props;

  // It will be run only once for the same src.
  useEffect(_ => {
    !loadedImages.has(src) && setImage({ src: src });
    const img = new Image();

    img.onload = _ => {
      loadedImages.add(src);
      setImage({ src });
      onLoad && onLoad();
    };

    img.src = src;
  }, [src, onLoad]);

  const imageClasses = classNames(
    classes.image,
    className,
    { [classes.loadedImage]: loadedImages.has(src) },
    { [classes.defaultImage]: !src },
  );

  return (
    <img
      src={image.src || DefaultImage}
      className={imageClasses}
      alt="ANM"
      {...imageProps}
    />
  );
}

Img.defaultProps = {
  src: DefaultImage,
};

Img.propTypes = {
  src: PropTypes.string,
  onLoad: PropTypes.func,
  className: PropTypes.string,
};

export default withStyles(styles)(Img);
