import SignIn from './';

const routes = [
  {
    exact: true,
    isMain: false,
    title: 'Sign In',
    component: SignIn,
    path: '/auth/sing-in',
  },
];

export default routes;
