import { gql } from 'apollo-boost';

export const userAll = gql`
  fragment userAll on User {
    id
    nodeId
    firstName
    lastName
    email
    createdAt
    updatedAt
    role
  }
`;
