import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import xor from 'lodash.xor';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

import Loading from 'components/Loading';
import DropZone from 'components/DropZone';

import ImagesModalContent from './ModalContent';

import { noop } from 'helpers';
const defaultImages = [];

function ImagesModal(props) {
  const [selectedImages, setSelectedImages] = useState(defaultImages);
  const [isModalOpen, setModalOpenStatus] = useState(false);
  const {
    images,
    onClose,
    multiple,
    onAccept,
    addImage,
    pageInfo,
    isLoading,
    fetchMore,
    removeImage,
    queryDataKey,
    acceptButtonText,
    allSelectedBtnText,
    onMultipleImgaeSelectAccept,
  } = props;

  const openModal = useCallback(() => {
    setModalOpenStatus(true);
  }, [setModalOpenStatus]);

  const closeModal = useCallback(() => {
    onClose();
    setModalOpenStatus(false);
    setSelectedImages(defaultImages);
  }, [onClose, setModalOpenStatus, setSelectedImages]);

  const handleAccept = useCallback(() => {
    onAccept(selectedImages[0]);
    closeModal();
  }, [closeModal, onAccept, selectedImages]);

  const handleImageSelect = useCallback(image => {
    setSelectedImages(xor(selectedImages, [image]));
  }, [setSelectedImages, selectedImages]);

  const handleMultipleImageSelectBtnClick = useCallback(() => {
    onMultipleImgaeSelectAccept(selectedImages);

    closeModal();
  }, [closeModal, onMultipleImgaeSelectAccept, selectedImages]);
  
  return (
    <>
      <Button
        onClick={openModal}
        variant="contained"
      >
        Images
      </Button>
      
      <Dialog
        fullScreen
        disablePortal
        scroll="paper"
        maxWidth={false}
        open={isModalOpen}
        onClose={closeModal}
        TransitionComponent={Slide}
      >
        <DialogTitle>
          <DropZone
            multiple={multiple}
            onRequest={addImage}
          />
        </DialogTitle>

        <DialogContent
          dividers
        >
          <Loading isLoading={isLoading}>
            <ImagesModalContent
              useWindow
              images={images}
              pageInfo={pageInfo}
              fetchMore={fetchMore}
              isLoading={isLoading}
              removeImage={removeImage}
              queryDataKey={queryDataKey}
              selectedImages={selectedImages}
              handleImageSelect={handleImageSelect}
            />
          </Loading>
        </DialogContent>

        <DialogActions>
          {allSelectedBtnText && (
            <Button
              color="primary"
              onClick={handleMultipleImageSelectBtnClick}
              disabled={!images.length || !selectedImages.length}
            >
              {allSelectedBtnText}
            </Button>
          )}

          <Button
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={handleAccept}
            disabled={!images.length || !selectedImages.length || selectedImages.length > 1}
          >
            {acceptButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ImagesModal.defaultProps = {
  onClose: noop,
  onAccept: noop,
  addImage: noop,
  multiple: true,
  removeImage: noop,
  updateImage: null,
  additionalButtons: [],
  allSelectedBtnText: '',
  acceptButtonText: 'Set as Main Image',
};

ImagesModal.propTypes = {
  pageInfo: PropTypes.shape({}),
  fetchMore: PropTypes.func,
  queryDataKey: PropTypes.string,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  multiple: PropTypes.bool,
  addImage: PropTypes.func,
  removeImage: PropTypes.func,
  acceptButtonText: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ImagesModal;
