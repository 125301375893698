import React from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  homeWrapper: {
    height: '100%',
  },
};

function HomePage(props) {
  const {
    classes,
  } = props;

  return (
    <div className={classNames(classes.homeWrapper, 'pad-sides-20 grow wrap flex-column align-center justify-center')}>
      <Typography variant="h6" color="primary" align="center">
        Please chose one of the sections from sidebar to add or edit data.
      </Typography>
    </div>
  );
}

export default withStyles(styles)(HomePage);
