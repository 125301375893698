// TODO: Jodit has soem issues. whenever we change "value" during onChange
// it turns the cursor to the start of editor
import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import xor from 'lodash.xor';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Label from 'components/Form/Label';

import { colors } from '_constants';

import Jodit from 'jodit';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';

import { noop } from 'helpers';

function convertPixelsToRem(rem) {
  return rem / 16;
}

const generatedFontSizes = {};

// Font Sizes
[
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  35,
  40,
  45,
  50,
].forEach(fontSize => {
  generatedFontSizes[fontSize] = `${fontSize} size`;
});

const defaultJoditBtns = xor(Jodit.defaultOptions.buttons, ['fontsize']);
const buttons = [...defaultJoditBtns, {
  name: 'Font Size',
  icon: 'fontsize',
  list: generatedFontSizes,
  exec: (editor, text, controls) => {
    editor.selection.applyCSS({
      'font-size': `${Number(convertPixelsToRem(controls.args[0]))}rem`,
    });
  },
  template: (key, value) => '<div>' + value + '</div>',
}];

const editorConfig = {
  controls: {
    font: {
      list: {
        '-apple-system,BlinkMacSystemFont,\'Segoe WPC\',\'Segoe UI\',HelveticaNeue-Light,Ubuntu,\'Droid Sans\',sans-serif': 'OS System Font',
        'FedraSansArm, sans-serif': 'FedraSansArm',
        'FedraSansArm-Bold, sans-serif': 'FedraSansArm-Bold',
        'SourceSansPro, sans-serif': 'SourceSansPro',
        'SourceSansPro-Bold, sans-serif': 'SourceSansPro-Bold',
      },
    }
  },
  enter: 'BR',
  minHeight: 400,
  spellcheck: true,
  defaultMode: 1,
  toolbarSticky: true,
  showCharsCounter: true,
  showWordsCounter: true,
  askBeforePasteHTML: true,
  toolbarButtonSize: 'large',
  showXPathInStatusbar: true,
  askBeforePasteFromWord: true,
  colorPickerDefaultTab: 'color',
  colors: {
    greyscale: [
      colors['text-color'],
      colors['secondary-text-color']
    ],
    palette: [
      colors['primary-blue'],
      colors['primary-blue-75'],
      colors['primary-blue-50'],
      colors['primary-blue-25'],
      colors.red,
      colors.green,
      colors.orange,
    ],
  },
  buttons: buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
};

function JoditTextEditor(props) {
  const {
    name,
    label,
    value,
    margin,
    dataLang,
    required,
    onChange,
    fullWidth,
    className,
    helperText,
  } = props;

  const handleEditorChange = useCallback(html => {
    onChange({
      [name]: html,
    }, name, { value: html, dataLang });
  }, [onChange, name, dataLang]);

  editorConfig.readonly = props.disabled;
  editorConfig.language = props.dataLang || 'en';

  return (
    <FormControl
      name={name}
      margin={margin}
      variant="outlined"
      required={required}
      fullWidth={fullWidth}
      className={classNames('text-editor', className)}
    >
      {label && (
        <Label
          labelFor={label}
          required={required}
          label={`${label} ${dataLang}`}
        />
      )}

      <JoditEditor
        value={value}
        config={editorConfig}
        onBlur={handleEditorChange}
      />

      {helperText && (
        <FormHelperText
          id={`describe-${label}`}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

JoditTextEditor.propsTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
};

JoditTextEditor.defaultProps = {
  name: '',
  value: '',
  label: '',
  onChange: noop,
  helperText: '',
  fullWidth: true,
  required: false,
  disabled: false,
  margin: 'normal',
};

export default JoditTextEditor;
