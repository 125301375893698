export { searchString } from './text/searchString';
export { checkPagePath } from './text/checkPagePath';
export { sanitizeString } from './text/sanitizeString';
export { copyToClipboard } from './text/copyToClipboard';
export { addImageProportions } from './text/addImageProportions';

export { searchInValues } from './array/searchInValues';
export { searchInTableData } from './array/searchInTableData';
export { searchWithLocales } from './array/searchWithLocales';
export { getSelectInputData } from './array/getSelectInputData';

export { getValue } from './language/getValue';

export { findLocale } from './localization/findLocale';

export { goToDetails } from './router/goToDetails';

export { downloadFile } from './files/downloadFile';

export { renderFormField } from './components/renderFormField';

export const noop = () => {};
