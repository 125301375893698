import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Image from 'components/Image';
import Loading from 'components/Loading';
import { FormSection } from 'components/Form';

import {
  FETCH_ARTICLE_GALLERY,
  DELETE_ARTICLE_GALLERY_IMAGE,
} from '_graphql/actions/articles';

function ArticleGallery(props) {
  const {
    galleryId,
  } = props;
  
  const [deleteArticleGalleryImage, { loading }] = useMutation(DELETE_ARTICLE_GALLERY_IMAGE);
  const { data: { articleGallery: { images = []} = {} } = {}, loading: galleryLoading } = useQuery(FETCH_ARTICLE_GALLERY, {
    variables: {
      galleryId,
    },
  });
  
  const handleArticleGalleryImageDelete = useCallback(async (image) => {
    await deleteArticleGalleryImage({
      variables: {
        imageId: image.id,
        galleryId,
      },
      refetchQueries: ['FetchArticleGallery'],
    });

  }, [deleteArticleGalleryImage, galleryId]);

  if (!images || !images.length) {
    return null;
  }

  return (
    <Loading isLoading={galleryLoading || loading}>
      <FormSection title="Gallery" className="flex-row wrap">
        {images.map(image => {
          return (
            <Image
              size={200}
              image={image}
              key={image.id}
              src={image.url}
              onDelete={handleArticleGalleryImageDelete}
            />
          )
        })}
      </FormSection>
    </Loading>
  );
}

ArticleGallery.defaultProps = {};

ArticleGallery.propTypes = {
  article: PropTypes.shape({}),
};

export default ArticleGallery;
