import React, { useCallback, useContext, useState } from 'react';

import { AuthContext } from 'pages/Auth/AuthProvider';

import Loading from 'components/Loading';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import styles from './styles';

const signModes = {
  reg: 'reg',
  signIn: 'signIn',
};

function SignIn(props) {
  const [signMode] = useState(signModes.signIn);
  const { signIn, isUserLoading } = useContext(AuthContext);
  const [formData, setFormData] = useState({});
  const isRegMode = signModes.reg === signMode;

  const handleInputChange = useCallback((event) => {
    const {
      name,
      value
    } = event.target;
    setFormData({ ...formData, [name]: value })
  }, [formData, setFormData]);
  
   const handleFormSubmit = useCallback(async event => {
    event.preventDefault();
    signIn(formData);
  }, [signIn, formData]);

  // const toggleSignMode = useCallback(() => {
  //   setSignMode(signMode => isRegMode ? signMode.signIn : signModes.reg);
  // }, [isRegMode]);

  const {
    classes,
  } = props;

  const isFormDisabled = !formData.email || !formData.password;
  return (
    <>
      {/*<Button onClick={toggleSignMode}>*/}
      {/*  {isRegMode ? 'Sign In' : 'Register'}*/}
      {/*</Button>*/}

      <Loading isLoading={isUserLoading}>
        <div className={classes.loginWrapper}>

          <form
            onSubmit={handleFormSubmit}
            className={classes.form}
          >
            {
              isRegMode ? (
                <>
                  <div className={classes.row}>
                    <TextField
                      onChange={handleInputChange}
                      label="First Name"
                      variant="outlined"
                      name="firstName"
                      fullWidth
                    />
                  </div>
                  <div className={classes.row}>
                    <TextField
                      onChange={handleInputChange}
                      variant="outlined"
                      label="Last Name"
                      name="lastName"
                      fullWidth
                    />
                  </div>
                  <div className={classes.row}>
                    <TextField
                      onChange={handleInputChange}
                      variant="outlined"
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                    />
                  </div>
                  <div className={classes.row}>
                    <TextField
                      onChange={handleInputChange}
                      variant="outlined"
                      label="Password"
                      name="password"
                      type="password"
                      fullWidth
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.row}>
                    <TextField
                      onChange={handleInputChange}
                      variant="outlined"
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                    />
                  </div>
                  <div className={classes.row}>
                    <TextField
                      onChange={handleInputChange}
                      variant="outlined"
                      label="Password"
                      name="password"
                      type="password"
                      fullWidth
                    />
                  </div>
                </>
              )
            }

            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={isFormDisabled}
            >
              {isRegMode ? 'Register' : 'Log In'}
            </Button>
          </form>
        </div>
      </Loading>
    </>
  );
}

export default withStyles(styles)(SignIn);

