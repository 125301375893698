// TODO: add useCallback
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  useMutation,
} from '@apollo/react-hooks';

import clone from 'lodash.clone';

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

import Paper from 'components/Paper';
import Table from 'components/Table';
import Loading from 'components/Loading';
import Form, { TextField } from 'components/Form';
import LocaleTabs from 'components/LocaleTabs';

import BaseSection from 'sections/BaseSection';

import { findLocale, searchWithLocales } from 'helpers';
import { languages } from '_constants';

const emptyItem = {
  name: '',
  locales: [],
};

const styles = {
  paper: {
    width: 600,
  },
};

const tableColumns = [
  ...languages.map(lang => (
    {
      title: 'Name ' + lang.code,
      getValue: item => findLocale(item.locales, lang.code).name,
    }
  )),
];

/**
 * @param props
 * @returns {*}
 * @constructor
 */
function SimpleDataSection(props) {
  const [isModalOpen, setModalOpenStatus] = useState(false);
  const [activeItem, setActiveItem] = useState({});

  const [createEmptyItem, { loading: emptyItemLoading }] = useMutation(props.addQuery);
  const [createItemLocale, { loading: createLoading }] = useMutation(props.addLocaleQuery);
  const [updateItemLocale, { loading: updateLoading }] = useMutation(props.updateLocaleQuery);
  const [deleteItem, { loading: deleteLoading }] = useMutation(props.deleteQuery);

  const {
    classes,
    dataKey,
    queryName,
    sectionTitle,
    createActionKey,
  } = props;

  const pluralDataKey = dataKey + 's';

  function openModal() {
    setModalOpenStatus(true);
  }

  function handleEdit(item) {
    setActiveItem(clone(item));
    openModal();
  }

  function handleAdd() {
    setActiveItem(clone(emptyItem));
    openModal();
  }

  function closeModal() {
    setModalOpenStatus(false);
    setActiveItem(clone(emptyItem));
  }

  const createLocale = data => createItemLocale({
    variables: data,
    refetchQueries: [queryName],
  });

  const updateLocale = data => updateItemLocale({
    variables: data,
    refetchQueries: [queryName],
  });

  const handleDelete = (id) => deleteItem({
    variables: { id },
    refetchQueries: [queryName],
  });

  async function handleFormSubmit(formData) {
    let item = clone(activeItem);

    /**
     * We should add a new item first and then add locales
     * for it.
     */
    if (!item.nodeId) {
      const newItem = await createEmptyItem();
      if (newItem) {
        item = newItem.data[createActionKey][dataKey];
        setActiveItem(item);
      } else {
        throw new Error('Something went wrong during adding a new item.');
      }
    }

    const action = formData.nodeId
      ? updateLocale
      : createLocale;

    await action({ ...formData, id: item.id });

    closeModal();
  }

  const isLoading = deleteLoading || updateLoading || createLoading || emptyItemLoading;

  return (
    <BaseSection
      title={sectionTitle}
      isLoading={isLoading}
      onAddButtonClick={handleAdd}
    >
      <Table
        onEdit={handleEdit}
        dataKey={pluralDataKey}
        onDelete={handleDelete}
        onDoubleClick={handleEdit}
        tableColumns={tableColumns}
        fetchQuery={props.fetchQuery}
        onRowFilter={searchWithLocales}
      />

      <Dialog
        open={isModalOpen}
        onClose={closeModal}
      >
        <Loading isLoading={isLoading}>
          <Paper
            className={classes.paper}
            key={activeItem.nodeId}
          >
            {isModalOpen && (
              <LocaleTabs renderContent={lang => {
                return (
                  <Form
                    key={lang.code}
                    title="Locales"
                    onCancel={closeModal}
                    isEdit={activeItem.id}
                    onSubmit={handleFormSubmit}
                    defaultData={activeItem.locales.find(loc => loc.lang === lang.code) || { name: '' }}
                    renderContent={(handleFormChange, formData) => {
                      const fieldName = 'name';
                      return (
                        <TextField
                          label="Name"
                          key={lang.code}
                          name={fieldName}
                          dataLang={lang.code}
                          onChange={handleFormChange}
                          value={formData[fieldName] || ''}
                        />
                      );
                    }}
                  />
                );
              }}/>
            )}
          </Paper>
        </Loading>
      </Dialog>
    </BaseSection>
  );
}

SimpleDataSection.propTypes = {
  queryName: PropTypes.string,
  addQuery: PropTypes.shape({}).isRequired,
  fetchQuery: PropTypes.shape({}).isRequired,
  deleteQuery  : PropTypes.shape({}).isRequired,
  addLocaleQuery: PropTypes.shape({}).isRequired,
  updateLocaleQuery: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(withRouter(SimpleDataSection));
