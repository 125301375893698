import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import AddFabButton from 'components/AddFabButton';
import Loading from 'components/Loading';

import ContentSection from 'sections/ContentSection';

const styles = theme => ({
  root: {
    width: '100%',
    overflow: 'auto',
    padding: 10,
  },
  title: {
    margin: '10px 0',
  },
  content: {
    padding: 10,
  },
});

/**
 * Main section for CRUD with filter and add button
 * @param props
 * @returns {*}
 * @constructor
 */
function BaseSection(props) {
  const {
    title,
    classes,
    children,
    isLoading,
    onAddButtonClick,
  } = props;

  return (
    <ContentSection>
      <div className={classes.root}>
        <Loading isLoading={isLoading}>
          <>
            {title && (
              <Typography
                variant="h4"
                color="primary"
                className={classes.title}
              >
                {title}
              </Typography>
            )}

            <div>
              {onAddButtonClick && (
                <AddFabButton
                  onClick={onAddButtonClick}
                />
              )}
            </div>
          </>

          <div className={classes.content}>
            {children}
          </div>
        </Loading>
      </div>
    </ContentSection>
  );
}

BaseSection.propTypes = {
  /**
   * Title of section
   */
  title: PropTypes.string,
  /**
   * Add button click event trigger
   */
  onAddButtonClick: PropTypes.func,
  /**
   * Should section show loader or not
   */
  isLoading: PropTypes.bool,
  /**
   * Classes of section.
   */
  classes: PropTypes.shape({}).isRequired,
};

BaseSection.defaultProps = {
  title: '',
  classes: {},
  isLoading: false,
};

export default withStyles(styles)(BaseSection);
