import { gql } from 'apollo-boost';
import { promoCodeAll } from '_graphql/fragments';

export const FETCH_PROMO_CODES = gql`
  query FetchPromoCodes($limit: Int, $offset: Int) {
    promoCodes(first: $limit, offset: $offset) {
      nodes {
        ...promoCodeAll
      }
      totalCount
    }
  }
  ${promoCodeAll}
`;

export const FETCH_SINGLE_PROMO_CODE = gql`
  query FetchSinglePromoCode ($code: String!) {
    promoCode(code: $code) {
      ...promoCodeAll
    }
  }

  ${promoCodeAll}
`;

export const CREATE_PROMO_CODE = gql`
  mutation CreatePromoCode(
    $status: PromoCodeStatus!
    $percent: Int!
    $expiresAt: Datetime
  ) {
    createPromoCode(
      input: {
          status: $status
          percent: $percent
          expiresAt: $expiresAt
      }
    ) {
      promoCode {
        ...promoCodeAll
      }
    }
  }
  ${promoCodeAll}
`;

export const UPDATE_PROMO_CODE = gql`
  mutation UpdatePromoCode(
    $code: String!
    $status: String!
    $percent: Int!
    $expiresAt: Datetime
  ) {
    updatePromoCode(
      input: {
        code: $code 
        patch: {
          status: $status
          percent: $percent
          expiresAt: $expiresAt
        }
      }
    ) {
      promoCode {
        ...promoCodeAll
      }
    }
  }
  ${promoCodeAll}
`;

export const DELETE_PROMO_CODE = gql`
  mutation DeletePromoCode($code: String!) {
    deletePromoCode(input: {
      code: $code
    }) {
      promoCode {
        ...promoCodeAll
      }
    }
  }
  ${promoCodeAll}
`;
