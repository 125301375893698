import { gql } from 'apollo-boost';

import { tagAll, tagLocaleAll } from '_graphql/fragments';

export const FETCH_TAGS = gql`
  query FetchTags($limit: Int, $offset: Int) {
    tags(first: $limit, offset: $offset) {
      nodes {
        ...tagAll
      }
      totalCount
    }
  }
  
  ${tagAll}
  ${tagLocaleAll}
`;

export const ADD_EMPTY_TAG = gql`
  mutation AddEmptyTag {
    createTag(input: { tag: { } }) {
      tag {
        ...tagAll
      }
    }
  }

  ${tagAll}
`;

export const ADD_TAG_LOCALE = gql`
  mutation CreateTagLocale($id: Int!, $name: String, $lang: String!) {
    createTagLocale(input: {
      tagLocale: {
        lang: $lang
        name: $name
        sourceId: $id
      }}) {
      locale: tagLocale {
        ...tagLocaleAll
      }
    }
  }
  ${tagLocaleAll}
`;

export const UPDATE_TAG_LOCALE = gql`
  mutation UpdateTagLocale($id: Int!, $name: String, $lang: String!) {
    updateTagLocale(
      input: {
        patch: {
          name: $name
          sourceId: $id
          lang: $lang
        },
        sourceId: $id
        lang: $lang
      }
    ) {
      locale: tagLocale {
        ...tagLocaleAll
      }
    }
  }
  ${tagLocaleAll}
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($id: Int!) {
    deleteTag(input: { id: $id }) {
      tag {
        id
        nodeId
      }
    }
  }
`;
