import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form, {
  FormSection,
  JoditTextEditor,
} from 'components/Form';

import Loading from 'components/Loading';

import { useMutation } from '@apollo/react-hooks';
import { findLocale } from 'helpers';

import LocaleTabs from 'components/LocaleTabs';

import {
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_LOCALE,
  UPDATE_DOCUMENT_LOCALE,
} from '_graphql/actions/termsAndPrivacy';

// import './styles.sass';

function TermsAndPrivacyDetails(props) {
  const [createDocument, { loading: isCreateLoading }] = useMutation(CREATE_DOCUMENT);
  const [createDocumentLocale, { loading: isCreateLocaleLoading }] = useMutation(CREATE_DOCUMENT_LOCALE, {
    refetchQueries: ['FetchDocuments'],
  });
  const [updateDocumentLocale, { loading: isUpdateLocaleLoading }] = useMutation(UPDATE_DOCUMENT_LOCALE, {
    refetchQueries: ['FetchDocuments'],
  });

  const {
    section,
    sectionDocument,
  } = props;
  
  const handleCreateLocale = useCallback((nodeId, formData) => {
    const isEdit = !!formData.nodeId;
    
    const action = isEdit
      ? updateDocumentLocale
      : createDocumentLocale;
    
    return action({
      variables: {
        id: nodeId,
        lang: formData.lang,
        content: formData.content,
      },
    });
  }, [updateDocumentLocale, createDocumentLocale]);
  
  const handleFormSubmit = useCallback(async data => {
    if (!data.content) {
      alert('Content can\'t be empty.');
      return;
    }

    if (!sectionDocument.name) {
      const newDoc = await createDocument({
        variables: {
          name: section,
          content: '',
        },
      });

      if (newDoc.data && newDoc.data.createDocument) {
        return handleCreateLocale(newDoc.data.createDocument.document.id, data);
      }
    } else {
      return handleCreateLocale(sectionDocument.id, data);
    }
  }, [createDocument, handleCreateLocale, sectionDocument, section]);

  return (
    <Loading isLoading={isCreateLoading || isCreateLocaleLoading || isUpdateLocaleLoading}>
      <FormSection title={section}>
        <LocaleTabs
          renderContent={lang => {
            return (
              <Form
                key={lang.code}
                onSubmit={handleFormSubmit}
                defaultData={findLocale(sectionDocument.locales, lang.code)}
                renderContent={(onFormChange, formData) => {
                  return (
                    <JoditTextEditor
                      name="content"
                      dataLang={lang.code}
                      onChange={onFormChange}
                      value={formData.content}
                    />
                  );
                }}
              />
            );
          }}
        />
      </FormSection>
    </Loading>
  );
}

TermsAndPrivacyDetails.defaultProps = {
  sectionDocument: {},
};

TermsAndPrivacyDetails.propTypes = {
  section: PropTypes.string.isRequired,
};

export default TermsAndPrivacyDetails;
