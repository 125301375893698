import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  wrapper: {
    position: 'relative',
    minHeight: 50,
    width: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255, .7)',
  },
  content: {
    minHeight: 50,
  },
};

function Loading(props) {
  const {
    style,
    classes,
    children,
    isLoading,
    spinnerColor,
    contentClassName,
    wrapperClassName,
    spinnerClassName,
  } = props;

  return (
    <div
      className={classNames(classes.wrapper, wrapperClassName)}
      style={style}
    >
      {isLoading && (
        <div className={classes.overlay}>
          <CircularProgress
            color={spinnerColor}
            className={spinnerClassName}
          />
        </div>
      )}
      <div className={classNames(contentClassName, classes.content)}>
        {children}
      </div>
    </div>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  spinnerClassName: PropTypes.string,
  spinnerColor: PropTypes.string,
};

Loading.defaultProps = {
  style: {},
  children: null,
  spinnerClassName: '',
  spinnerColor: 'primary',
};

export default withStyles(styles)(Loading);
