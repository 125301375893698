import TermsAndPrivacy from './index';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/terms-and-privacy',
    title: 'Privacy & Policy',
    component: TermsAndPrivacy,
  },
];

export default routes;
