import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import { noop } from 'helpers';

function AddFabButton(props) {
  const {
    onClick,
  } = props;

  return (
    <Fab
      size="small"
      color="primary"
      onClick={onClick}
    >
      <AddIcon />
    </Fab>
  );
}

AddFabButton.defaultProps = {
  onClick: noop,
};

AddFabButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddFabButton;
