import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    overflow: 'auto',
    marginTop: 40,
    backgroundColor: theme.palette.background.paper,
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.grey[200],
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  smallCell: {
    maxWidth: 40,
  },
  table: {
    marginTop: 30,
  },
}));
