import Instruments from './';

const routes = [
  {
    exact: true,
    isMain: true,
    path: '/instruments',
    title: 'Instruments',
    component: Instruments,
  },
];

export default routes;
